import { BindRemarksDrawer } from '../BindRemarksDrawer'
import { EliminationResultDrawer } from '../EliminationResultDrawer'
import { FormItemsWrapper, FormWrapper, FullWrapper } from './PrescriptionsForm.styles'
import {
  PrescriptionAutofilledData,
  PrescriptionDrawerType,
  PrescriptionFormData,
  PrescriptionFormDialogTrigger,
  PrescriptionLocationState,
} from './PrescriptionsForm.types'
import { Control } from './components/Control'
import { Info } from './components/Info'
import { Remarks } from './components/Remarks'
import { validationPrescription } from './validation'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import {
  prescriptionsApi,
  useBindRemarksMutation,
  useCreatePrescriptionMutation,
  useDeleteFilesFromPrescriptionMutation,
  useDeletePrescriptionMutation,
  useEditPrescriptionMutation,
  useGetBindRemarksQuery, // useGetBindRemarksMutation,
  useGetNextPrescriptionNumberQuery,
  useGetPrescriptionByIdQuery,
  useLazyCheckPrescriptionForEmailNotificationQuery,
  useLazyCheckPrescriptionForFilterSatisfactionQuery,
  useLazyFormPrescriptionQuery,
  useUploadFilesToPrescriptionMutation,
} from 'api/prescriptions'
import { RemarkFull } from 'api/remarks/types'
import { Button } from 'components/Button'
import { Progress } from 'components/Progress'
import { SimpleHeader } from 'components/SimpleHeader'
import { prescriptionInspectionRuByEn, prescriptionTypeRuByEn } from 'core/types/prescription'
import { Form, FormikProvider } from 'formik'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useTypedSelector } from 'store'
import { isUserHighAccessSelector, profileSelector } from 'store/slices/profile'
import { theme } from 'styles/theme'
import { connectNames } from 'utils/connectNames'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { downloadMedia } from 'utils/downloadMedia'

export const PrescriptionsForm: FC = () => {
  const profile = useTypedSelector(profileSelector)
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  const viewingOnly = !isUserHighAccess
  const { projectId: projectIdString, prescriptionId: prescriptionIdString } = useParams()
  const projectId = Number(projectIdString)
  const prescriptionId = Number(prescriptionIdString) || undefined
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [isPrescriptionForming, setIsPrescriptionForming] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  // Location
  const location = useLocation()
  const locationState: PrescriptionLocationState = (location?.state as PrescriptionLocationState) || {}

  // Clearing locationState
  useEffect(() => {
    if (locationState) navigate(location.pathname, { replace: true })
  }, [])

  const savedLocationState = useMemo(() => locationState, [])

  const { sortableColumn, filterData, prescriptionValues, currentPage } = savedLocationState || {}

  const formMode: 'add' | 'edit' = location.pathname.includes('/add') ? 'add' : 'edit'

  const {
    data: prescriptionData,
    isFetching: isPrescriptionDataFetching,
    isLoading: isPrescriptionLoading,
  } = useGetPrescriptionByIdQuery(
    {
      projectId,
      prescriptionId: prescriptionId!,

      sort: sortableColumn,
      filter: filterData,
    },
    {
      skip: !prescriptionId,
    },
  )

  const {
    status,
    number,
    period,
    inspectionType,
    type,
    comment,
    senderCompany,
    senderUser,
    contractor,
    receiver,
    executionControl,
    attachments,
    preloadedShortcomings,
    canEditStatus,
    author,
  } = prescriptionData || {}

  const { dateStart, dateEnd, dateDone, automaticDateEnd } = period || {}

  const [createPrescription, { isLoading: isCreating, ...createPrescriptionResponse }] = useCreatePrescriptionMutation()
  const [editPrescription, { isLoading: isEditing, ...editPrescriptionResponse }] = useEditPrescriptionMutation()
  const [deletePrescription, { isLoading: isDeleting, ...deletePrescriptionResponse }] = useDeletePrescriptionMutation()
  const [uploadFilesToPrescriptionRequest, { isLoading: isFilesUploading, ...uploadFilesToPrescriptionResponse }] =
    useUploadFilesToPrescriptionMutation()
  const [deleteFilesFromPrescriptionRequest, { isLoading: isFilesDeleting, ...deleteFilesFromPrescriptionResponse }] =
    useDeleteFilesFromPrescriptionMutation()
  const [formPrescriptionRequest, formPrescriptionResult] = useLazyFormPrescriptionQuery()
  // const [getBindRemarks, { data: boundRemarksData, isSuccess: isBoundRemarksSuccess }] = useGetBindRemarksMutation()

  const [bindRemarks, bindRemarksResponse] = useBindRemarksMutation()
  const [checkForFilterSatisfaction, { isLoading: isSatisfactionChecking, ...checkForFilterSatisfactionResult }] =
    useLazyCheckPrescriptionForFilterSatisfactionQuery()
  const [
    checkPrescriptionForEmailNotification,
    { isLoading: isEmailNotificationChecking, ...checkEmailNotificationResult },
  ] = useLazyCheckPrescriptionForEmailNotificationQuery()
  const isQuerying = isCreating || isEditing || bindRemarksResponse.isLoading || isFilesUploading || isFilesDeleting
  const [isFormLoading, setIsFormLoading] = useState(false)

  const isStatusChangeAvailable = !viewingOnly && formMode !== 'add' && canEditStatus

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const [autofilledData, setAutofilledData] = useState<PrescriptionAutofilledData>({
    contractor: null,
  })

  const {
    data: nextNumber,
    isFetching: isNextNumberFetching,
    isLoading: isPrescriptionNumberLoading,
  } = useGetNextPrescriptionNumberQuery(projectId)

  const initialValues = useMemo(() => {
    const data: PrescriptionFormData = {
      status: status || 'CREATED',
      number: number || nextNumber || '',
      dateStart: parseResponseDate(dateStart).date || new Date(new Date().setHours(0, 0, 0, 0)),
      inspectionType: inspectionType
        ? {
            id: inspectionType,
            value: prescriptionInspectionRuByEn[inspectionType],
          }
        : null,
      type: {
        id: type || 'CONTINUE_WORK',
        value: type ? prescriptionTypeRuByEn[type] : 'Без остановки работ',
      },
      comment: comment || '',
      senderCompany:
        senderCompany || profile.company.userCompanyName
          ? {
              id: senderCompany?.company || senderCompany?.projectMember?.id || profile.company.userCompanyName,
              value:
                senderCompany?.company || senderCompany?.projectMember?.shortName || profile.company.userCompanyName,
              type: senderCompany?.projectMember ? 'projectMember' : 'company',
            }
          : null,
      senderUser:
        senderUser?.representative || senderUser?.user || profile
          ? {
              id: senderUser?.representative?.id || senderUser?.user?.id || profile?.id || 0,
              value: senderUser?.representative?.fullName || connectNames(senderUser?.user || profile),
              subtext:
                senderUser?.representative || senderUser?.user
                  ? senderUser?.representative?.position || senderUser?.user?.position
                  : profile.company.userPosition || '',
              type: senderUser?.representative ? 'representative' : 'engineerQC',
            }
          : null,
      contractor: contractor
        ? {
            id: contractor.id,
            value: contractor.shortName,
          }
        : null,
      receiver: receiver
        ? {
            id: receiver?.id,
            value: receiver?.fullName,
            subtext: `${receiver?.position || '—'}, ${receiver.projectMember.shortName || '—'}`,
          }
        : null,
      executionControl: executionControl
        ? {
            id: executionControl?.id,
            value: executionControl?.fullName,
            subtext: `${executionControl?.position || '—'}, ${executionControl.projectMember.shortName || '—'}`,
          }
        : null,
      files: attachments || [],
      filesForCreate: [],
      filesIdsToDelete: [],

      dateEnd: parseResponseDate(dateEnd).date,
      dateDone: parseResponseDate(dateDone).date,

      remarks: preloadedShortcomings || [],
      choice: null,
      automaticDateEnd: typeof automaticDateEnd == 'boolean' ? automaticDateEnd : true,
      // remarksIdsToDelete: [],
      author: author || null,
    }

    return data
  }, [profile, nextNumber, prescriptionData])

  const isDirtyWithoutStatusAndDateDone = (values: PrescriptionFormData): boolean => {
    const { status, dateDone, ...localInitialValues }: Partial<PrescriptionFormData> = { ...initialValues }
    const {
      status: statusValue,
      dateDone: dateDoneValue,
      ...localValues
    }: Partial<PrescriptionFormData> = { ...values }

    return !isEqual(localInitialValues, localValues)
  }

  const onSubmit = useCallback(
    (values: PrescriptionFormData, isShouldNotify: boolean, isNotificationRequest?: boolean) => {
      const {
        status,
        dateStart,
        dateEnd,
        dateDone,
        inspectionType,
        type,
        comment,
        senderCompany,
        senderUser,
        contractor,
        receiver,
        executionControl,
        automaticDateEnd,
      } = values

      if (dateDone && status !== 'COMPLETE') {
        enqueueSnackbar(
          'Предписание имеет факт. дату устранения. Измените статус на Исполнено, либо удалите факт. дату устранения.',
          { variant: 'error' },
        )

        return
      }

      const dataForRequest = {
        number: initialValues.number === values.number ? null : values.number,
        status,
        period: {
          dateStart: formatDateForServer(dateStart),
          dateEnd: formatDateForServer(dateEnd),
          dateDone: dateDone ? formatDateForServer(dateDone) : null,
          automaticDateEnd,
        },
        inspectionType: inspectionType?.id,
        type: type?.id,
        comment: comment,
        senderCompany: {
          company: senderCompany?.type === 'company' ? senderCompany.value : null,
          projectMember: senderCompany?.type === 'projectMember' ? senderCompany.id : null,
        },
        senderUser: {
          engineerQC: senderUser?.type === 'engineerQC' ? senderUser.id : null,
          representative: senderUser?.type === 'representative' ? senderUser.id : null,
        },
        contractor: contractor?.id,
        receiver: receiver?.id,
        executionControl: executionControl?.id,
      }

      if (isNotificationRequest && prescriptionId) {
        checkPrescriptionForEmailNotification({
          projectId,
          prescriptionId: prescriptionId,
          body: dataForRequest,
        })

        return
      }

      if (prescriptionId) {
        if (
          isDirtyWithoutStatusAndDateDone(values) &&
          initialValues.status !== 'CREATED' &&
          values.status !== 'CREATED'
        ) {
          enqueueSnackbar('Изменение данных в предписании возможно только в статусе Создано.', { variant: 'error' })
          return
        }

        editPrescription({
          projectId,
          prescriptionId,
          body: dataForRequest,
          sort: sortableColumn,
          filter: filterData,
          notification: isShouldNotify,
        })
      } else {
        createPrescription({ projectId, body: dataForRequest })
      }
    },
    [initialValues, isDirtyWithoutStatusAndDateDone],
  )

  const { formik } = useForm({
    validationSchema: validationPrescription,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      onSubmit(values, isNotificationRequest, isNotificationRequest)
    },
  })

  const { values, setFieldValue, dirty, isValid, setValues, setTouched } = formik

  const [isLocalValid, setIsLocalValid] = useState<boolean>(isValid)
  const isNotificationRequest = Boolean(
    prescriptionId &&
      (values.status === 'SENT' || values.status === 'COMPLETE' || values.status === 'DISCARDED') &&
      initialValues.status !== values.status,
  )
  const [isDataSinchronized, setIsDataSinchronized] = useState(false)
  const isDataSinchronizedSaved = useMemo(() => isDataSinchronized, [isDataSinchronized])
  useEffect(() => {
    if (
      !isDataSinchronizedSaved &&
      prescriptionValues &&
      !isPrescriptionLoading &&
      !isPrescriptionNumberLoading &&
      !isEqual(prescriptionValues, values)
    ) {
      setValues(prescriptionValues)
      setIsDataSinchronized(true)
    }
  }, [prescriptionValues, isPrescriptionLoading, isPrescriptionNumberLoading])

  useEffect(() => {
    if (values.executionControl && values.receiver) setIsLocalValid(true)
    else setIsLocalValid(false)
  }, [values])

  const { data: getBindRemarksResponse, isLoading } = useGetBindRemarksQuery(
    {
      projectId,
      body: { choice: values.choice! },
      prescriptionId,
      chosen: true,
    },
    { skip: !values.choice },
  )

  useEffect(() => {
    if (!getBindRemarksResponse) return

    const remarks =
      getBindRemarksResponse?.data?.reduce<RemarkFull[]>((remarks, remarkData) => {
        remarks.push(remarkData.shortcoming)
        return remarks
      }, []) || []

    setFieldValue('remarks', remarks)

    if (values.automaticDateEnd && getBindRemarksResponse?.deadline) {
      setFieldValue('dateEnd', parseResponseDate(getBindRemarksResponse?.deadline).date)
      setTouched({ dateEnd: true })
    }
  }, [getBindRemarksResponse])

  // useEffect(() => {
  //   if (isBoundRemarksSuccess) {
  //     const remarks =
  //       boundRemarksData?.data?.reduce<RemarkFull[]>((remarks, remarkData) => {
  //         remarks.push(remarkData.shortcoming)
  //         return remarks
  //       }, []) || []
  //     setFieldValue('remarks', remarks)

  //     if (values.automaticDateEnd) {
  //       setFieldValue('dateEnd', parseResponseDate(boundRemarksData?.deadline).date)
  //       setTouched({ dateEnd: true })
  //     }
  //   }
  // }, [boundRemarksData])

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) onReturnClick()
  }, [])

  const handleDeleteConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm && prescriptionId) {
        deletePrescription({ projectId, prescriptionId })
      }
    },
    [values],
  )

  const handleCloseDrawer = useCallback((confirm: boolean) => {
    if (confirm) setOpenedDrawer(null)
  }, [])

  const handleNotificationConfirm = useCallback(
    (confirm: boolean) => {
      onSubmit(values, confirm)
    },
    [values],
  )

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<PrescriptionFormDialogTrigger>('exit')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    exit: { handleConfirm: handleExitConfirm },
    delete: {
      handleConfirm: handleDeleteConfirm,
      title: 'Удалить предписание?',
      body: 'Предписание будет удалено.',
    },
    closeBind: { handleConfirm: handleCloseDrawer },
    notification: {
      handleConfirm: handleNotificationConfirm,
      title: 'Отправить уведомление?',
      body: (
        <Typography variant='body2' color={theme.palette.text.dark}>
          Уведомление о смене статуса будет направлено на e-mail представителей подрядчика.
        </Typography>
      ),
      denyButtonText: 'нет',
    },
    eliminationResult: { handleConfirm: handleCloseDrawer },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onReturn = useCallback(
    (dirty: boolean, immediately?: boolean) => {
      setConfirmDialogTrigger('exit')
      let localDirty = dirty

      if (Object.values(autofilledData).some((autofilledItem) => autofilledItem)) {
        const localInitialValues = initialValues

        for (let key in autofilledData) {
          // @ts-ignore
          if (!localInitialValues[key]) localInitialValues[key] = autofilledData[key]
        }

        localDirty = !isEqual(localInitialValues, values)
      }

      immediately || !localDirty ? onReturnClick() : openConfirm()
    },
    [autofilledData, values],
  )

  const onDelete = useCallback(() => {
    setConfirmDialogTrigger('delete')
    openConfirm()
  }, [])

  const uploadFilesToPrescription = (id: number) => {
    uploadFilesToPrescriptionRequest({ projectId, prescriptionId: id, files: [...values.filesForCreate].reverse() })
  }

  const deleteFilesFromPrescription = () => {
    prescriptionId &&
      deleteFilesFromPrescriptionRequest({
        projectId,
        prescriptionId,
        filesIds: values.filesIdsToDelete,
      })
  }

  const formPrescription = () => {
    setIsPrescriptionForming(true)
    prescriptionId && formPrescriptionRequest({ projectId, prescriptionId })
  }

  const handleError = useCallback((showSnackbar = true) => {
    showSnackbar && enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
    setIsFormLoading(false)
  }, [])

  const [openedDrawer, setOpenedDrawer] = useState<PrescriptionDrawerType | null>(null)

  const onDrawerOpen = useCallback((drawerType: PrescriptionDrawerType) => {
    setOpenedDrawer(drawerType)
  }, [])

  const onDrawerClose = useCallback(
    (dialogType: PrescriptionFormDialogTrigger, dirty: boolean, immediately?: boolean) => {
      if (immediately || !dirty) setOpenedDrawer(null)
      else {
        setConfirmDialogTrigger(dialogType)
        openConfirm()
      }
    },
    [],
  )

  useMutationHandlers(checkEmailNotificationResult, (data) => {
    if (data.data) {
      setConfirmDialogTrigger('notification')
      openConfirm()
    } else {
      onSubmit(values, false)
    }
  })

  useMutationHandlers(
    createPrescriptionResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      if (values.choice) {
        bindRemarks({
          projectId,
          prescriptionId: data.data.id,
          body: { ...values.choice, all: false },
        })
      }

      if (values.filesForCreate.length) uploadFilesToPrescription(data.data.id)

      if (!values.choice && !values.filesForCreate.length) {
        dispatch(prescriptionsApi.util.invalidateTags(['Prescriptions', 'ProjectsDashboard']))
        setIsFormLoading(false)
      }

      navigate(`/project/${projectId}/prescriptions/edit/${data.data.id}`)
      enqueueSnackbar('Предписание успешно добавлено.', { variant: 'success' })
    },
    (data) => {
      // @ts-ignore
      if (data?.status === 409) {
        enqueueSnackbar(`Предписание с данным номером уже существует.`, { variant: 'error' })
      }
      // @ts-ignore
      handleError(data?.status !== 409)
    },
  )

  useMutationHandlers(
    editPrescriptionResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      if (values.choice) {
        bindRemarks({
          projectId,
          prescriptionId: data.data.id,
          atomic: data.atomic.addShortcomings,
          body: values.choice,
        })
      }

      if (values.filesForCreate.length) {
        uploadFilesToPrescription(data.data.id)
      }

      if (values.filesIdsToDelete.length && !values.filesForCreate.length) {
        deleteFilesFromPrescription()
      }

      if (!values.filesForCreate.length && !values.filesIdsToDelete.length && !values.choice) {
        // dispatch(prescriptionsApi.util.invalidateTags(['Prescriptions']))
        dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'PRESCRIPTION_BY_ID' }]))
      }

      if (values.filesForCreate.length || values.filesIdsToDelete.length) return

      enqueueSnackbar('Предписание успешно изменено.', { variant: 'success' })
      setIsFormLoading(false)
    },
    (data) => {
      // @ts-ignore
      if (data?.status === 409) {
        enqueueSnackbar(`Предписание с данным номером уже существует.`, { variant: 'error' })
      }
      // @ts-ignore
      handleError(data?.status !== 409)
    },
  )

  useMutationHandlers(
    uploadFilesToPrescriptionResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      if (values.filesIdsToDelete.length) {
        deleteFilesFromPrescription()
      } else {
        dispatch(prescriptionsApi.util.invalidateTags(['Prescriptions']))
        enqueueSnackbar(prescriptionId ? 'Предписание успешно изменено.' : 'Предписание успешно добавлено.', {
          variant: 'success',
        })
        if (!prescriptionId) navigate(`/project/${projectId}/prescriptions/edit/${data.data.id}`)
      }
      setIsFormLoading(false)
    },
    () => handleError(),
  )

  useMutationHandlers(
    deleteFilesFromPrescriptionResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      dispatch(prescriptionsApi.util.invalidateTags(['Prescriptions']))
      enqueueSnackbar('Предписание успешно изменено.', { variant: 'success' })
      setIsFormLoading(false)
    },
    () => handleError(),
  )

  useMutationHandlers(
    deletePrescriptionResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar('Предписание успешно удалено.', { variant: 'success' })
      onReturnClick(false)
    },
    () => handleError(),
  )

  useMutationHandlers(
    bindRemarksResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      dispatch(
        prescriptionsApi.util.invalidateTags([
          { type: 'Prescriptions', id: 'PRESCRIPTION_BY_ID' },
          { type: 'Prescriptions', id: 'PRESCRIPTIONS_LIST' },
        ]),
      )
      // enqueueSnackbar('Предписание успешно добавлено.', { variant: 'success' })
      setIsFormLoading(false)
      // dispatch(prescriptionsApi.util.invalidateTags(['Prescriptions']))
    },
    () => handleError(),
  )

  useEffect(() => {
    const { status, data } = formPrescriptionResult

    if (status === 'fulfilled' && data && isPrescriptionForming) {
      downloadMedia(data.link)
      enqueueSnackbar('Файл успешно сгенерирован.', { variant: 'success' })
      setIsPrescriptionForming(false)
    }
  }, [formPrescriptionResult])

  useEffect(() => {
    const { status, data } = checkForFilterSatisfactionResult

    if (status === 'fulfilled' && data) onReturnClick()
  }, [checkForFilterSatisfactionResult])

  const onReturnClick = (isShouldCheckFilterSatisfaction: boolean = true) => {
    const appliedFilter = Object.values(filterData || {}).some((filterableValue) => filterableValue) ? filterData : null

    if (
      appliedFilter &&
      prescriptionId &&
      checkForFilterSatisfactionResult.status !== 'fulfilled' &&
      isShouldCheckFilterSatisfaction
    ) {
      checkForFilterSatisfaction({
        projectId,
        prescriptionId,
        filter: appliedFilter,
        sort: sortableColumn,
      })
    } else {
      navigate(`/project/${projectId}/prescriptions`, {
        state: {
          sortableColumn,
          filterData,
          prescriptionId,
          prescriptionsPage: currentPage,
          transaction: true,
        },
      })
    }
  }

  return (
    <>
      {prescriptionId && (isPrescriptionDataFetching || isNextNumberFetching) ? (
        <Progress />
      ) : (
        <Stack width={'100%'} bgcolor={theme.palette.bg.white}>
          <SimpleHeader
            title={((viewingOnly && 'Просмотр ') || (prescriptionId ? 'Редактирование ' : 'Создание ')) + 'предписания'}
            onClose={() => onReturn(dirty, false)}
          >
            {formMode === 'edit' && (
              <Button disabled={dirty} size='medium' onClick={() => onDrawerOpen('eliminationResult')}>
                Результат устранения
              </Button>
            )}
          </SimpleHeader>
          <FormWrapper>
            <FormikProvider value={formik}>
              <Stack component={Form}>
                <FullWrapper>
                  <FormItemsWrapper>
                    <Info
                      viewingOnly={viewingOnly}
                      formMode={formMode}
                      isStatusChangeAvailable={!!isStatusChangeAvailable}
                    />

                    <Control
                      viewingOnly={viewingOnly}
                      setIsLocalValid={setIsLocalValid}
                      formMode={formMode}
                      setAutofilledData={setAutofilledData}
                    />

                    <Remarks
                      viewingOnly={viewingOnly}
                      onBindDrawerOpen={() => onDrawerOpen('remarks')}
                      currentPage={currentPage}
                      savedLocationState={savedLocationState}
                    />
                  </FormItemsWrapper>

                  <Stack direction='row' spacing={2} justifyContent={'flex-end'} paddingRight={1.5}>
                    {!viewingOnly && (
                      <>
                        {prescriptionId ? (
                          <>
                            <Button onClick={onDelete} icon={true} size='medium' color='error' variant='outlined'>
                              <DeleteIcon style={{ fill: theme.palette.error.main }} />
                              <Typography color={theme.palette.error.main} marginLeft={1}>
                                Удалить предписание
                              </Typography>
                            </Button>
                            <Button
                              onClick={formPrescription}
                              disabled={dirty || (!isValid && !isLocalValid) || isFormLoading}
                              loading={isPrescriptionForming}
                              color='success'
                              style={{ maxWidth: '100%' }}
                            >
                              Сформировать предписание
                            </Button>
                          </>
                        ) : null}

                        <Button
                          type='submit'
                          disabled={!dirty || !isValid || !isLocalValid}
                          loading={isFormLoading}
                          color='success'
                          size='medium'
                        >
                          Сохранить
                        </Button>
                      </>
                    )}
                    <Button size='medium' onClick={() => onReturn(dirty, false)}>
                      Закрыть
                    </Button>
                  </Stack>
                </FullWrapper>
              </Stack>

              <BindRemarksDrawer
                open={openedDrawer === 'remarks'}
                onClose={(dirty, immediately) => onDrawerClose('closeBind', dirty, immediately)}
                rootChoice={values.choice}
              />
            </FormikProvider>
          </FormWrapper>
        </Stack>
      )}

      <ConfirmDialog />

      <EliminationResultDrawer
        isOpen={openedDrawer === 'eliminationResult'}
        onClose={(dirty, immediately) => onDrawerClose('eliminationResult', dirty, immediately)}
        prescriptionDateStart={values.dateStart}
        prescriptionStatus={initialValues.status}
      />
    </>
  )
}
