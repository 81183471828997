import { AgGridReact } from 'ag-grid-react'
import styled from 'styled-components'

export const StyledAgGrid = styled(AgGridReact)`
  .ag-root-wrapper {
    border: none;
    background-color: transparent;
  }

  .ag-header {
    &-container {
      background-color: ${(props) => props.theme.palette.secondary.gray};
    }

    &-cell {
      background-color: ${(props) => props.theme.palette.secondary.gray};

      &-label {
        justify-content: center;
        color: ${(props) => props.theme.palette.text.light};
        letter-spacing: 0.17px;
        line-height: 143%;
      }
    }
  }

  .ag-body-vertical-scroll {
    background-color: ${(props) => props.theme.palette.bg.white};
  }

  .ag-row {
    border-bottom: none;

    &-hover {
      background-color: ${(props) => props.theme.palette.bg.shades};
      cursor: pointer;

      &::before {
        content: none;
      }
    }
  }

  .ag-cell {
    box-shadow: rgba(0, 36, 95, 0.1) -1px -1px 0px inset;
    padding: 8px;

    &-wrapper {
      height: 100%;
    }

    &-value {
      color: ${(props) => props.theme.palette.text.dark};
      line-height: 143%;
      letter-spacing: 0.17px;
    }
  }
`

export const StyledCallTable = styled(StyledAgGrid)`
  [col-id='type'],
  [col-id='assignmentType.title'],
  [col-id='description'],
  [col-id='author'],
  [col-id='responsibleCompany'],
  [col-id='responsibleUser'],
  [col-id='description'] {
    text-align: start;
  }

  [col-id='status'],
  [col-id='audit'] {
    padding: 0;

    .ag-cell-value {
      height: 100%;
    }
  }
`
