import { UserRoles } from 'core/types/user'

export const basicReferences: Reference[] = [
  {
    id: 'ASSIGNMENT_TYPE',
    name: 'Виды работ',
    columns: [
      { name: 'Вид работ', required: true, id: 'title' },
      { name: 'Описание', required: false, id: 'description' },
      { name: 'Примечание', required: false, id: 'note' },
    ],
    api: {
      getList: { url: '/assignment-type/list', projectUrl: '/assignment-type/{project}/list' },
      createSection: { url: '/assignment-type/create', projectUrl: '/assignment-type/{project}/create' },
      editSection: { url: '/assignment-type/{id}/update', projectUrl: '/assignment-type/{project}/{id}/update' },
      deleteSection: { url: '/assignment-type/{id}/delete', projectUrl: '/assignment-type/{project}/{id}/delete' },
      upload: { url: '/assignment-type/import', projectUrl: '/assignment-type/{project}/import' },
      getUploadResult: {
        url: '/assignment-type/import/result',
        projectUrl: '/assignment-type/{project}/import/result',
      },
      clear: { url: '/assignment-type/delete/all', projectUrl: '/assignment-type/{project}/delete/all' },
      export: { url: '/assignment-type/export', projectUrl: '/assignment-type/{project}/export' },
      getExportResult: {
        url: '/assignment-type/export/result',
        projectUrl: '/assignment-type/{project}/export/result',
      },
    },
  },
  {
    id: 'PROJECT_MEMBER',
    name: 'Участники проекта',
    columns: [
      { name: 'Краткое наименование', required: true, id: 'shortName' },
      { name: 'Полное наименование', required: false, id: 'fullName' },
      { name: 'ФИО руководителя', required: true, id: 'supervisorName' },
      { name: 'Должность руководителя', required: true, id: 'supervisorPosition' },
      { name: 'Примечание', required: false, id: 'note' },
    ],
    api: {
      getList: { url: '/project-member/list', projectUrl: '/project-member/{project}/list' },
      createSection: { url: '/project-member/create', projectUrl: '/project-member/{project}/create' },
      editSection: { url: '/project-member/{id}/update', projectUrl: '/project-member/{project}/{id}/update' },
      deleteSection: { url: '/project-member/{id}/delete', projectUrl: '/project-member/{project}/{id}/delete' },
      upload: { url: '/project-member/import', projectUrl: '/project-member/{project}/import' },
      getUploadResult: { url: '/project-member/import/result', projectUrl: '/project-member/{project}/import/result' },
      clear: { url: '/project-member/delete/all', projectUrl: '/project-member/{project}/delete/all' },
    },
  },
  {
    id: 'PROJECT_MEMBER_REPRESENTATIVE',
    name: 'Представители участников проекта',
    columns: [
      { name: 'ФИО', required: true, id: 'fullName' },
      { name: 'Должность', required: false, id: 'position' },
      { name: 'Подрядчик', required: true, id: 'projectMember' },
      { name: 'E-mail', required: false, id: 'email' },
      { name: 'Примечание', required: false, id: 'note' },
    ],
    api: {
      getList: {
        url: '/project-member-representative/list',
        projectUrl: '/project-member-representative/{project}/list',
      },
      createSection: {
        url: '/project-member-representative/create',
        projectUrl: '/project-member-representative/{project}/create',
      },
      editSection: {
        url: '/project-member-representative/{id}/update',
        projectUrl: '/project-member-representative/{project}/{id}/update',
      },
      deleteSection: {
        url: '/project-member-representative/{id}/delete',
        projectUrl: '/project-member-representative/{project}/{id}/delete',
      },
      upload: {
        url: '/project-member-representative/import',
        projectUrl: '/project-member-representative/{project}/import',
      },
      getUploadResult: {
        url: '/project-member-representative/import/result',
        projectUrl: '/project-member-representative/{project}/import/result',
      },
      clear: {
        url: '/project-member-representative/delete/all',
        projectUrl: '/project-member-representative/{project}/delete/all',
      },
    },
  },
  {
    id: 'RD',
    name: 'Разделы РД',
    columns: [
      { name: 'Марка', required: true, id: 'mark' },
      { name: 'Наименование', required: true, id: 'fullName' },
      { name: 'Примечание', required: false, id: 'note' },
    ],
    api: {
      getList: { url: '/rd/list', projectUrl: '/rd/{project}/list' },
      createSection: { url: '/rd/create', projectUrl: '/rd/{project}/create' },
      editSection: { url: '/rd/{id}/update', projectUrl: '/rd/{project}/{id}/update' },
      deleteSection: { url: '/rd/{id}/delete', projectUrl: '/rd/{project}/{id}/delete' },
      upload: { url: '/rd/import', projectUrl: '/rd/{project}/import' },
      getUploadResult: { url: '/rd/import/result', projectUrl: '/rd/{project}/import/result' },
      clear: { url: '/rd/delete/all', projectUrl: '/rd/{project}/delete/all' },
    },
  },
  {
    id: 'DOCUMENTS',
    name: 'Виды документов',
    columns: [
      { name: 'Краткое наименование', required: true, id: 'title' },
      { name: 'Полное наименование', required: true, id: 'fullName' },
      { name: 'Примечание', required: false, id: 'note' },
    ],
    api: {
      getList: { url: '/document-types/list', projectUrl: '/document-types/{project}/list' },
      createSection: { url: '/document-types/create', projectUrl: '/document-types/{project}/create' },
      editSection: { url: '/document-types/{id}/update', projectUrl: '/document-types/{project}/{id}/update' },
      deleteSection: { url: '/document-types/{id}/delete', projectUrl: '/document-types/{project}/{id}/delete' },
      upload: { url: '/document-types/import', projectUrl: '/document-types/{project}/import' },
      getUploadResult: { url: '/document-types/import/result', projectUrl: '/document-types/{project}/import/result' },
      clear: { url: '/document-types/delete/all', projectUrl: '/document-types/{project}/delete/all' },
    },
  },
  {
    id: 'DEFECT',
    name: 'Дефекты',
    columns: [
      { name: 'Код дефекта', required: true, id: 'code' },
      { name: 'Наименование', required: true, id: 'title' },
      { name: 'Вид дефекта', required: true, id: 'type' },
      { name: 'Примечание', required: false, id: 'note' },
    ],
    api: {
      getList: { url: '/defect/list', projectUrl: '/defect/{project}/list' },
      createSection: { url: '/defect/create', projectUrl: '/defect/{project}/create' },
      editSection: { url: '/defect/{id}/update', projectUrl: '/defect/{project}/{id}/update' },
      deleteSection: { url: '/defect/{id}/delete', projectUrl: '/defect/{project}/{id}/delete' },
      upload: { url: '/defect/import', projectUrl: '/defect/{project}/import' },
      getUploadResult: { url: '/defect/import/result', projectUrl: '/defect/{project}/import/result' },
      clear: { url: '/defect/delete/all', projectUrl: '/defect/{project}/delete/all' },
    },
  },
  {
    id: 'TEMPLATES',
    name: 'Шаблоны документов',
    access: 'admin',
    doubleRow: [
      [
        { name: 'Вид документа', required: true, id: 'name' },
        { name: 'Нумерация документа', required: true, id: '' },
        {
          name: 'Используемый шаблон',
          required: false,
          id: 'link',
          tooltipTxt:
            'Шаблон, на основании которого формируются документы в формах Предписание и Результат устранения.',
        },
        { name: 'Примечание', required: false, id: 'note' },
      ],
      [
        { name: 'Префикс', required: true, id: 'prefix' },
        { name: 'Суффикс', required: true, id: 'suffix' },
      ],
    ],
    columns: [
      { name: 'Вид документа', required: false, id: 'name' },
      { name: 'Префикс', required: false, id: 'prefix' },
      { name: 'Суффикс', required: false, id: 'suffix' },
      { name: 'Шаблон', required: false, id: 'link' },
      { name: 'Примечание', required: false, id: 'note' },
      { name: 'Оригинал', required: false, id: 'originalLink' },
    ],
    api: {
      getList: { url: '/print-form/list', projectUrl: '/print-form/{project}/list' },
      createSection: { url: '/rd/create', projectUrl: '/rd/{project}/create' },
      editSection: { url: '/print-form/{id}/update', projectUrl: '/print-form/{project}/{id}/update' },
      deleteSection: { url: '/rd/{id}/delete', projectUrl: '/rd/{project}/{id}/delete' },
      upload: { url: '/rd/import', projectUrl: '/rd/{project}/import' },
      getUploadResult: { url: '/rd/import/result', projectUrl: '/rd/{project}/import/result' },
      clear: { url: '/rd/delete/all', projectUrl: '/rd/{project}/delete/all' },
      updatePattern: {
        url: '/print-form/{id}/update/template',
        projectUrl: '/print-form/{project}/{id}/update/template',
      },
    },
  },
]

export interface Reference {
  id: string
  name: string
  columns: Column[]
  api: ReferenceApi
  doubleRow?: Column[][]
  access?: UserRoles
}

export interface Column {
  id: string
  name: string
  required: boolean
  tooltipTxt?: string
}

export interface ReferenceApi {
  getList: ReferenceApiService
  createSection: ReferenceApiService
  editSection: ReferenceApiService
  deleteSection: ReferenceApiService
  upload: ReferenceApiService
  getUploadResult: ReferenceApiService
  export?: ReferenceApiService
  getExportResult?: ReferenceApiService
  clear: ReferenceApiService
  updatePattern?: ReferenceApiService
}

export interface ReferenceApiService {
  url: string
  projectUrl: string
}
