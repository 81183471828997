import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'
import { FC } from 'react'

export const NumberIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M11.9062 6.5625H10.4227C10.3453 6.5625 10.282 6.62578 10.282 6.70312V14.6766H10.1484L4.62656 6.62344C4.60078 6.58594 4.55625 6.5625 4.51172 6.5625H2.95312C2.87578 6.5625 2.8125 6.62578 2.8125 6.70312V17.5781C2.8125 17.6555 2.87578 17.7188 2.95312 17.7188H4.42266C4.5 17.7188 4.56328 17.6555 4.56328 17.5781V9.49453H4.69687L10.2797 17.6578C10.3055 17.6953 10.35 17.7188 10.3969 17.7188H11.9062C11.9836 17.7188 12.0469 17.6555 12.0469 17.5781V6.70312C12.0469 6.62578 11.9836 6.5625 11.9062 6.5625ZM20.7656 16.2422H13.6406C13.5375 16.2422 13.4531 16.3266 13.4531 16.4297V17.5547C13.4531 17.6578 13.5375 17.7422 13.6406 17.7422H20.7656C20.8687 17.7422 20.9531 17.6578 20.9531 17.5547V16.4297C20.9531 16.3266 20.8687 16.2422 20.7656 16.2422ZM17.1984 14.7656C18.4383 14.7656 19.4297 14.3625 20.1562 13.5539C20.8453 12.7828 21.1875 11.7773 21.1875 10.5352C21.1875 9.29063 20.8453 8.27344 20.1562 7.50469C19.432 6.68906 18.4406 6.28125 17.1984 6.28125C15.9398 6.28125 14.9508 6.69141 14.2383 7.51875C13.5539 8.29453 13.2211 9.29766 13.2211 10.5352C13.2211 11.7633 13.5562 12.7664 14.2406 13.5422C14.9578 14.3555 15.9516 14.7656 17.1984 14.7656ZM15.5227 8.58516C15.9187 8.10234 16.4672 7.86094 17.1961 7.86094C17.9344 7.86094 18.4805 8.08594 18.8602 8.54297C19.2445 9.01875 19.4437 9.68203 19.4437 10.5328C19.4437 11.3836 19.2469 12.0352 18.8625 12.4992C18.4758 12.9539 17.925 13.1836 17.1961 13.1836C16.4648 13.1836 15.907 12.9422 15.5227 12.4711C15.1406 12 14.9484 11.3625 14.9484 10.5352C14.9508 9.69609 15.1406 9.05859 15.5227 8.58516Z' />
    </SvgIcon>
  )
}
