import { FC, useState, MouseEvent } from 'react'
import { ICallStatusCellProps } from './CallStatusCell.types'
import { StatusCellContent, StatusCellMenuItemProps } from 'pages/Remarks/components/RemarksTable/cells/StatusCell'
import { ColoredTitle } from 'components/ColoredTitle'
import { callStatusColorByEn, callStatuses, callStatusRuByEn } from 'core/types/call'
import { TCallStatus } from 'api/calls/types'
import { KeyboardArrowDown as ArrowIcon } from '@mui/icons-material'
import { IconButton, Menu } from '@mui/material'
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection'
import { useChangeCallStatusMutation } from 'api/calls'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useTypedSelector } from 'store/store'
import { profileSelector } from 'store/slices/profile'
import { ModuleRegistry } from 'ag-grid-community'
import { RowApiModule } from 'ag-grid-community'

ModuleRegistry.registerModules([RowApiModule])

export const CallStatusCell: FC<ICallStatusCellProps> = (props) => {
  const { value, data, api } = props
  const { role, company } = useTypedSelector(profileSelector)
  const access: boolean =
    role === 'admin' ||
    (role === 'engineer_qc' && company.userCompanyName === data?.author.company.userCompanyName) ||
    (role === 'contractor' && data?.status !== 'COMPLETE')
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const status = value as TCallStatus
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const [changeCallStatus, { ...changeCallStatusResponse }] = useChangeCallStatusMutation()
  const { enqueueSnackbar } = useSnackbar()

  const onStatusCellClick = (e: MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(e.currentTarget)
  }

  const onMenuClose = () => {
    setMenuAnchor(null)
  }

  const onMenuClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const statusCellMenuItemsData: StatusCellMenuItemProps<TCallStatus>[] = callStatuses
    .filter((menuStatus) => menuStatus !== value)
    .map((menuStatus) => {
      const valueForOnClick = menuStatus
      const checked = status === valueForOnClick

      return {
        value: menuStatus,
        valueForOnClick,
        checked: checked,
        key: menuStatus,
        children: callStatusRuByEn[menuStatus],
      }
    })

  const menuItemClickHandler = (status: TCallStatus) => () => {
    setMenuAnchor(null)

    changeCallStatus({ projectId, callId: data!.id, status })
  }

  useMutationHandlers(changeCallStatusResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    api.applyTransaction({ update: [data.data] })

    enqueueSnackbar('Статус успешно изменён.', { variant: 'success' })
  })

  return (
    <>
      <StatusCellContent onClick={onStatusCellClick} pl={access ? 2.75 : 0} height={'100%'} alignItems={'center'}>
        <ColoredTitle body={callStatusRuByEn[status]} color={callStatusColorByEn[status]} />

        {access && (
          <IconButton>
            <ArrowIcon fontSize='small' />
          </IconButton>
        )}
      </StatusCellContent>
      {access && (
        <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClick={onMenuClick} onClose={onMenuClose}>
          {(statusCellMenuItemsData || []).map(({ value, valueForOnClick, children, checked, key }) => {
            if (value) {
              return (
                <StyledSelectMenuItem
                  onClick={menuItemClickHandler(valueForOnClick)}
                  style={{ width: menuAnchor?.clientWidth, maxWidth: '100%' }}
                  value={value}
                  checked={checked}
                  key={key}
                >
                  {children}
                </StyledSelectMenuItem>
              )
            }
          })}
        </Menu>
      )}
    </>
  )
}
