import { api } from '../api'
import {
  ChangeRemarkEliminationRequest,
  ChangeRemarkEliminationResponse,
  ChangeRemarkNotificationRequest,
  ChangeRemarkNotificationResponse,
  CheckRemarkForFilterSatisfactionRequest,
  CheckRemarkForFilterSatisfactionResponse,
  CreateRemarkSecondaryRequest,
  CreateRemarkSecondaryResponse,
  CreateRemarkRequest,
  CreateRemarkResponse,
  DeleteImagesFromRemarkRequest,
  DeleteMediaFromEliminationRequest,
  DeleteRemarkCopyRequest,
  DeleteRemarkRequest,
  DeleteRemarkResponse,
  EditRemarkCopyRequest,
  EditRemarkCopyResponse,
  EditRemarkRequest,
  EditRemarkResponse,
  EditRemarkStatusRequest,
  EditRemarkStatusResponse,
  GetDropdownActivityTypesResponse,
  GetDropdownBasisResponse,
  GetDropdownContractorsResponse,
  GetDropdownDataRequest,
  GetDropdownObjectsResponse,
  GetDropdownResponsibleRequest,
  GetDropdownResponsibleResponse,
  GetRemarkAuditRequest,
  GetRemarkAuditResponse,
  GetRemarkEliminationRequest,
  GetRemarkEliminationResponse,
  GetRemarkRequest,
  GetRemarkResponse,
  GetRemarksColumnSettingsResponse,
  GetRemarksRequest,
  GetRemarksResponse,
  GetResponsibleEliminationRequest,
  ResetRemarksColumnSettingsResponse,
  SetRemarksColumnSettingsResponse,
  UploadImagesToRemarkRequest,
  UploadImagesToRemarkResponse,
  UploadMediaToEliminationRequest,
  UploadMediaToEliminationResponse,
  GetRemarkSecondaryAuditResponse,
  GetRemarksByPrevIdRequest,
  CheckRemarkForEmailNotificationResponse,
  CheckRemarkForEmailNotificationRequest,
  CheckRemarkStatusForEmailNotificationResponse,
  CheckRemarkStatusForEmailNotificationRequest,
  IGetDescriptionsForRemarkResponse,
  IGetDescriptionsForRemarkRequest
} from './api.types'
import { prescriptionsApi } from 'api/prescriptions'
import {
  GetColumnSettingsRequest,
  GetDropdownCompanySendersResponse,
  GetDropdownSendersRequest,
  GetDropdownSendersResponse,
  GetPrescriptionFiltredlistRequest,
  GetPrescriptionFiltredlistResponse,
  ResetColumnSettingsRequest,
  SetColumnSettingsRequest,
} from 'api/prescriptions/api.types'
import { projectsApi } from 'api/projects'
import { TABLE_CELL_HEIGHT } from 'pages/Remarks/components/RemarksTable/RemarksTable.styles'

export const remarksApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRemarks: build.query<GetRemarksResponse, GetRemarksRequest>({
      query: ({ projectId, sort, filter, ...params }) => {
        const appliedFilter = Object.values(filter || {}).some((filterableValue) => filterableValue) ? filter : null
        const appliedSorting = sort?.column ? { columns: [sort] } : null
        return {
          url: `/shortcoming/${projectId}/list`,
          params,
          body: {
            filter: appliedFilter,
            sort: appliedSorting,
          },
          method: 'POST',
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const sortChanged =
          currentArg?.sort?.order !== previousArg?.sort?.order || currentArg?.sort?.column !== previousArg?.sort?.column
        const filterChanged = JSON.stringify(currentArg?.filter) !== JSON.stringify(previousArg?.filter)
        const otherArgsChanged = sortChanged || filterChanged

        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
        // return pageChanged || sortChanged || filterChanged
      },
      providesTags: ['Remarks'],
    }),
    getRemarksByPrevId: build.query<GetRemarksResponse, GetRemarksByPrevIdRequest>({
      query: ({ projectId, sort, filter, scrollByIndex, returnScrollPosition, ...params }) => {
        const appliedFilter = Object.values(filter || {}).some((filterableValue) => filterableValue) ? filter : null
        const appliedSorting = sort?.column ? { columns: [sort] } : null
        return {
          url: `/shortcoming/${projectId}/list/experimental`,
          params,
          body: {
            filter: appliedFilter,
            sort: appliedSorting,
          },
          method: 'POST',
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        const { last, centered, reverse, returnScrollPosition, scrollByIndex, filter } = arg || {}

        if (!last || centered) {
          currentCache.data = [...newData.data]

          if (centered && scrollByIndex) {
            let { targetId, rowIndex } = scrollByIndex(newData.data)

            let scrolledItem = newData.data[rowIndex]

            if (filter?.status?.length && !filter.status.includes(scrolledItem.status)) {
              newData.data.splice(rowIndex, 1)
              currentCache.data = newData.data
            }
          }
          return
        }

        if (reverse) {
          currentCache.data.unshift(...newData.data)
          if (returnScrollPosition) returnScrollPosition(newData.data.length)
        } else currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        // const pageChanged = currentArg?.page !== previousArg?.page
        const lastItemChanged = currentArg?.last !== previousArg?.last
        const sortChanged =
          currentArg?.sort?.order !== previousArg?.sort?.order || currentArg?.sort?.column !== previousArg?.sort?.column
        const filterChanged = JSON.stringify(currentArg?.filter) !== JSON.stringify(previousArg?.filter)
        const otherArgsChanged = sortChanged || filterChanged

        if (currentArg && otherArgsChanged) {
          currentArg.last = null
          currentArg.centered = false
          currentArg.reverse = false
        }

        return lastItemChanged || otherArgsChanged
        // return pageChanged || sortChanged || filterChanged
      },
      providesTags: ['Remarks'],
    }),
    getRemark: build.query<GetRemarkResponse, GetRemarkRequest>({
      query: ({ projectId, remarkId }) => ({
        url: `/shortcoming/${projectId}/${remarkId}/get`,
        method: 'GET',
      }),
      providesTags: ['Remarks', { type: 'Remarks', id: 'REMARK_BY_ID' }],
    }),
    getDropdownObjects: build.query<GetDropdownObjectsResponse, GetDropdownDataRequest>({
      query: ({ projectId }) => ({
        url: `/shortcoming/${projectId}/create/dropdown/object`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['Objects'],
    }),
    getDropdownCompanySenders: build.query<GetDropdownCompanySendersResponse, GetDropdownDataRequest>({
      query: ({ projectId }) => ({
        url: `/prescription/${projectId}/create/dropdown/sender-company`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['Projects'],
    }),
    getDropdownSenders: build.query<GetDropdownSendersResponse, GetDropdownSendersRequest>({
      query: ({ projectId, ...params }) => ({
        url: `/prescription/${projectId}/create/dropdown/sender`,
        params: { page: 1, num: 99999, ...params },
        method: 'GET',
      }),
      providesTags: ['References'],
    }),
    getDropdownActivityTypes: build.query<GetDropdownActivityTypesResponse, GetDropdownDataRequest>({
      query: ({ projectId }) => ({
        url: `/shortcoming/${projectId}/create/dropdown/assignment-type`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['References'],
    }),
    getDescriptionsForRemark: build.query<IGetDescriptionsForRemarkResponse, IGetDescriptionsForRemarkRequest>({
      query: ({ projectId, assignmentId }) => ({
        url: `/shortcoming/${projectId}/create/dropdown/description`,
        params: { page: 1, num: 99999, assignmentId },
        method: 'GET',
      }),
      providesTags: ['References'],
    }),
    getDropdownContractors: build.query<GetDropdownContractorsResponse, GetDropdownDataRequest>({
      query: ({ projectId }) => ({
        url: `/shortcoming/${projectId}/create/dropdown/contractor`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['References', 'Users'],
    }),
    getDropdownResponsible: build.query<GetDropdownResponsibleResponse, GetDropdownResponsibleRequest>({
      query: ({ projectId, contractor, company }) => ({
        url: `/shortcoming/${projectId}/create/dropdown/responsible?${contractor ? `&contractor=${contractor}` : ''}${
          company ? `&company=${company}` : ''
        }`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['References'],
    }),
    getResponsibleElimination: build.query<GetDropdownResponsibleResponse, GetResponsibleEliminationRequest>({
      query: ({ projectId, remarkId }) => ({
        url: `/shortcoming/${projectId}/${remarkId}/update/contractor-status/dropdown/responsible`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['References'],
    }),
    getDropdownBasis: build.query<GetDropdownBasisResponse, GetDropdownDataRequest>({
      query: ({ projectId }) => ({
        url: `/shortcoming/${projectId}/create/dropdown/basis`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['RemarksDropdown'],
    }),
    createRemark: build.mutation<CreateRemarkResponse, CreateRemarkRequest>({
      query: ({ projectId, body }) => ({
        url: `/shortcoming/${projectId}/create`,
        method: 'POST',
        body,
      }),
      // invalidatesTags: ['Remarks', 'ProjectsDashboard'],
      invalidatesTags: ['ProjectsDashboard', { type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }],
    }),
    createRemarkSecondary: build.mutation<CreateRemarkSecondaryResponse, CreateRemarkSecondaryRequest>({
      query: ({ projectId, remarkId, body }) => ({
        url: `/shortcoming/${projectId}/${remarkId}/secondary/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProjectsDashboard', { type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }],
    }),
    getNextRemarkNumber: build.query<number, number>({
      query: (projectId) => ({
        url: `/shortcoming/${projectId}/create/number`,
        method: 'GET',
      }),
      providesTags: ['Remarks'],
    }),
    editRemark: build.mutation<EditRemarkResponse, EditRemarkRequest>({
      query: ({ projectId, remarkId, body, ...params }) => ({
        url: `/shortcoming/${projectId}/${remarkId}/update`,
        method: 'PUT',
        params,
        body,
      }),
      async onQueryStarted({ projectId, remarkId, body, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { data: updatedRemark } = data || {}

          // getRemarksByPrevId
          dispatch(
            remarksApi.util.updateQueryData('getRemarksByPrevId', { projectId }, (draft) => {
              const updatedRemarkIndex = draft.data.findIndex((remark) => remark.id === updatedRemark.id)
              draft.data[updatedRemarkIndex] = updatedRemark
            }),
          )

          // getRemarkElimination
          dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Remarks', id: 'REMARKS_ELIMINATION' }]))

          // getRemarkAudit
          dispatch(remarksApi.util.invalidateTags([{ type: 'Remarks', id: 'REMARKS_AUDIT' }]))

          dispatch(prescriptionsApi.util.invalidateTags(['Prescriptions']))
          dispatch(prescriptionsApi.util.invalidateTags(['ProjectsDashboard']))
        } catch {}
      },
    }),
    editRemarkCopy: build.mutation<EditRemarkCopyResponse, EditRemarkCopyRequest>({
      query: ({ projectId, primaryRemarkId, remarkId, body, ...params }) => ({
        url: `/shortcoming/${projectId}/${primaryRemarkId}/secondary/${remarkId}/update`,
        method: 'PUT',
        params,
        body,
      }),
      async onQueryStarted({ projectId, remarkId, body, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { data: updatedRemark } = data || {}

          // getRemarksByPrevId
          dispatch(
            remarksApi.util.updateQueryData('getRemarksByPrevId', { projectId }, (draft) => {
              const updatedRemarkIndex = draft.data.findIndex((remark) => remark.id === updatedRemark.id)
              draft.data[updatedRemarkIndex] = updatedRemark
            }),
          )

          // getRemarkElimination
          dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Remarks', id: 'REMARKS_ELIMINATION' }]))

          // getRemark
          // dispatch(
          //   prescriptionsApi.util.invalidateTags([
          //     { type: 'Remarks', id: 'REMARK_BY_ID' }
          //   ])
          // )

          // getRemarkAudit
          dispatch(remarksApi.util.invalidateTags([{ type: 'Remarks', id: 'REMARKS_AUDIT' }]))

          // // getEliminationResultSelectableRemarks
          // dispatch(
          //   prescriptionsApi.util.invalidateTags([
          //     { type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }
          //   ])
          // )

          // Prescriptions
          dispatch(prescriptionsApi.util.invalidateTags(['Prescriptions']))
          dispatch(prescriptionsApi.util.invalidateTags(['ProjectsDashboard']))
        } catch {}
      },
    }),
    checkRemarkStatusForEmailNotification: build.query<
      CheckRemarkStatusForEmailNotificationResponse,
      CheckRemarkStatusForEmailNotificationRequest
    >({
      query: ({ projectId, remarkId, secondaryId, status, completion }) => {
        return {
          url: !secondaryId
            ? `/shortcoming/${projectId}/${remarkId}/update/status/email-check`
            : `/shortcoming/${projectId}/${remarkId}/secondary/${secondaryId}/update/status/email-check`,
          method: 'GET',
          params: { status, completion },
        }
      },
    }),
    editRemarkStatus: build.query<EditRemarkStatusResponse, EditRemarkStatusRequest>({
      query: ({ projectId, remarkId, status, dateDone, notification, secondaryId }) => ({
        url: !secondaryId
          ? `/shortcoming/${projectId}/${remarkId}/update/status`
          : `/shortcoming/${projectId}/${remarkId}/secondary/${secondaryId}/update/status`,
        method: 'get',
        params: { status, completion: dateDone, notification },
      }),
      async onQueryStarted({ projectId, remarkId, sort, filter, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedRemarkData } = await queryFulfilled
          const updatedRemark = updatedRemarkData.data

          dispatch(
            remarksApi.util.updateQueryData('getRemarksByPrevId', { projectId }, (draft) => {
              const updatedRemarkIndex = draft.data.findIndex((remark) => remark.id === updatedRemark.id)
              draft.data[updatedRemarkIndex] = updatedRemark
            }),
          )
          //Наверное, сюда нужно добавить еще логику из за появления secondaryId
          // getRemark не работает
          // dispatch(
          //   remarksApi.util.updateQueryData(
          //     'getRemark',
          //     { projectId, remarkId },
          //     (draft) => {
          //       draft = updatedRemark
          //     }
          //   )
          // )

          // getRemark
          dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Remarks', id: 'REMARK_BY_ID' }]))

          // getRemarkAudit
          dispatch(remarksApi.util.invalidateTags([{ type: 'Remarks', id: 'REMARKS_AUDIT' }]))

          /* добавил для обновления дашборда проекта а так же в методы создания и удаления */
          dispatch(projectsApi.util.invalidateTags([{ type: 'ProjectsDashboard' }]))

          // Prescriptions
          dispatch(prescriptionsApi.util.invalidateTags(['Prescriptions']))
        } catch {}
      },
      providesTags: ['Remarks'],
    }),
    uploadImagesToRemark: build.mutation<UploadImagesToRemarkResponse, UploadImagesToRemarkRequest>({
      query: ({ projectId, remarkId, files }) => {
        const formData = new FormData()

        files.forEach((file) => {
          // const { temporaryId, ...localFile } = file

          formData.append('file', file)
        })

        return {
          url: `/shortcoming/${projectId}/${remarkId}/update/media/add`,
          method: 'POST',
          body: formData,
        }
      },
      // async onQueryStarted({ projectId, remarkId, ...patch }, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data } = await queryFulfilled
      //     const { data: updatedRemark } = data || {}

      //     // getRemark
      //     dispatch(
      //       prescriptionsApi.util.invalidateTags([
      //         { type: 'Remarks', id: 'REMARK_BY_ID' }
      //       ])
      //     )

      //   } catch {
      //   }
      // },
    }),
    deleteImagesFromRemark: build.mutation<any, DeleteImagesFromRemarkRequest>({
      query: ({ projectId, remarkId, imagesIds }) => ({
        url: `/shortcoming/${projectId}/${remarkId}/update/media/remove`,
        params: { img: imagesIds },
        method: 'DELETE',
      }),
      // async onQueryStarted({ projectId, remarkId, ...patch }, { dispatch, queryFulfilled }) {
      //   try {
      //     // getRemark
      //     dispatch(
      //       prescriptionsApi.util.invalidateTags([
      //         { type: 'Remarks', id: 'REMARK_BY_ID' }
      //       ])
      //     )
      //   } catch {
      //   }
      // },
    }),
    deleteRemark: build.mutation<DeleteRemarkResponse, DeleteRemarkRequest>({
      query: ({ projectId, remarkId }) => ({
        url: `/shortcoming/${projectId}/${remarkId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Remarks', 'ProjectsDashboard', 'Prescriptions'],
    }),
    deleteRemarkCopy: build.mutation<DeleteRemarkResponse, DeleteRemarkCopyRequest>({
      query: ({ projectId, primaryRemarkId, remarkId }) => ({
        url: `/shortcoming/${projectId}/${primaryRemarkId}/secondary/${remarkId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Remarks', 'ProjectsDashboard', 'Prescriptions'],
    }),
    changeRemarkNotification: build.mutation<ChangeRemarkNotificationResponse, ChangeRemarkNotificationRequest>({
      query: ({ projectId, remarkId, ...params }) => ({
        url: `/shortcoming/${projectId}/${remarkId}/update/notifications`,
        params,
        method: 'GET',
      }),
      // async onQueryStarted({ projectId, remarkId, ...patch }, { dispatch, queryFulfilled }) {
      //   try {

      //     // getRemark
      //     dispatch(
      //       prescriptionsApi.util.invalidateTags([
      //         { type: 'Remarks', id: 'REMARK_BY_ID' }
      //       ])
      //     )

      //   } catch {
      //   }
      // },
    }),

    getRemarkAudit: build.query<GetRemarkAuditResponse, GetRemarkAuditRequest>({
      query: ({ projectId, remarkId, ...params }) => ({
        url: `audit/${projectId}/shortcoming/${remarkId}`,
        params,
        method: 'GET',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
          remarkId: queryArgs.remarkId,
        }
      },
      merge: (currentCacheData, responseData, args) => {
        if (args.arg.page === 1) {
          return responseData
        }

        currentCacheData.data.push(...responseData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const projectChanged = currentArg?.projectId !== previousArg?.projectId

        const otherArgsChanged = projectChanged
        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: ['Remarks', { type: 'Remarks', id: 'REMARKS_AUDIT' }],
    }),
    getRemarkAuditSecondary: build.query<GetRemarkSecondaryAuditResponse, GetRemarkAuditRequest>({
      query: ({ projectId, remarkId, ...params }) => ({
        url: `audit/${projectId}/shortcoming/${remarkId}/secondary`,
        params,
        method: 'GET',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
          remarkId: queryArgs.remarkId,
        }
      },
      merge: (currentCacheData, responseData, args) => {
        if (args.arg.page === 1) {
          return responseData
        }

        currentCacheData.data.push(...responseData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const projectChanged = currentArg?.projectId !== previousArg?.projectId

        const otherArgsChanged = projectChanged
        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: ['Remarks', { type: 'Remarks', id: 'REMARKS_AUDIT' }],
    }),

    getRemarksFilteredList: build.query<GetPrescriptionFiltredlistResponse, GetPrescriptionFiltredlistRequest>({
      query: ({ projectId, columnId, filter, ...params }) => {
        return {
          url: `/shortcoming/${projectId}/list/filters/${columnId}`,
          method: 'POST',
          params: params,
          body: filter,
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        currentCache.total = newData.total

        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const queryChanged = currentArg?.query !== previousArg?.query
        const columnIdChanged = currentArg?.columnId !== previousArg?.columnId
        const filterChanged = JSON.stringify(currentArg?.filter) !== JSON.stringify(previousArg?.filter)

        const otherArgsChanged = queryChanged || columnIdChanged || filterChanged

        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || queryChanged || columnIdChanged || filterChanged
      },
    }),
    checkRemarkForFilterSatisfaction: build.query<
      CheckRemarkForFilterSatisfactionResponse,
      CheckRemarkForFilterSatisfactionRequest
    >({
      query: ({ projectId, remarkId, filter }) => {
        return {
          url: `/shortcoming/${projectId}/${remarkId}/get`,
          method: 'POST',
          body: filter,
        }
      },
      async onQueryStarted({ projectId, remarkId, filter, sort, ...patch }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          const { shortcoming: updatedRemark, pass } = data || {}

          // getRemarksByPrevId
          dispatch(
            remarksApi.util.updateQueryData('getRemarksByPrevId', { projectId, sort, filter }, (draft) => {
              if (pass) return

              const updatedRemarkIndex = draft.data.findIndex((prescription) => prescription.id === updatedRemark.id)
              draft.data.splice(updatedRemarkIndex, 1)
            }),
          )
        } catch {}
      },
    }),
    checkRemarkForEmailNotification: build.query<
      CheckRemarkForEmailNotificationResponse,
      CheckRemarkForEmailNotificationRequest
    >({
      query: ({ projectId, remarkId, body, secondaryId }) => {
        return {
          url: !secondaryId
            ? `/shortcoming/${projectId}/${remarkId}/update/email-check`
            : `/shortcoming/${projectId}/${remarkId}/secondary/${secondaryId}/update/email-check`,
          method: 'PUT',
          body: body,
        }
      },
    }),
    getRemarkElimination: build.query<GetRemarkEliminationResponse, GetRemarkEliminationRequest>({
      query: ({ projectId, remarkId }) => ({
        url: `/shortcoming/${projectId}/${remarkId}/update/contractor-status`,
        method: 'GET',
      }),
      providesTags: ['Remarks', { type: 'Remarks', id: 'REMARKS_ELIMINATION' }],
    }),
    changeRemarkElimination: build.mutation<ChangeRemarkEliminationResponse, ChangeRemarkEliminationRequest>({
      query: ({ projectId, remarkId, body, ...params }) => ({
        url: `/shortcoming/${projectId}/${remarkId}/update/contractor-status`,
        params,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ projectId, remarkId, status, body, ...patch }, { dispatch, queryFulfilled }) {
        try {
          // getRemarksByPrevId
          dispatch(
            remarksApi.util.updateQueryData('getRemarksByPrevId', { projectId }, (draft) => {
              const updatedRemarkIndex = draft.data.findIndex((remark) => remark.id === remarkId)
              draft.data[updatedRemarkIndex].contractorStatus = status
            }),
          )

          // getPrescription
          dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'PRESCRIPTION_BY_ID' }]))

          // getRemarkAudit
          dispatch(remarksApi.util.invalidateTags([{ type: 'Remarks', id: 'REMARKS_AUDIT' }]))
        } catch {}
      },
    }),
    uploadMediaToElimination: build.mutation<UploadMediaToEliminationResponse, UploadMediaToEliminationRequest>({
      query: ({ projectId, remarkId, files }) => {
        const formData = new FormData()

        files.forEach((file) => {
          // const { temporaryId, ...localFile } = file

          formData.append('file', file)
        })

        return {
          url: `/shortcoming/${projectId}/${remarkId}/update/contractor-status/media/add`,
          method: 'POST',
          body: formData,
        }
      },
    }),
    deleteMediaFromElimination: build.mutation<any, DeleteMediaFromEliminationRequest>({
      query: ({ projectId, remarkId, mediaIds }) => ({
        url: `/shortcoming/${projectId}/${remarkId}/update/contractor-status/media/remove`,
        params: { img: mediaIds },
        method: 'DELETE',
      }),
    }),

    getRemarksColumnSettings: build.query<GetRemarksColumnSettingsResponse, GetColumnSettingsRequest>({
      query: ({ projectId }) => ({
        url: `/shortcoming/${projectId}/list/view/load`,
        method: 'GET',
      }),
      providesTags: ['RemarksTableColumns'],
    }),
    setRemarksColumnSettings: build.mutation<SetRemarksColumnSettingsResponse, SetColumnSettingsRequest>({
      query: ({ projectId, body }) => ({
        url: `/shortcoming/${projectId}/list/view/save`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RemarksTableColumns'],
    }),
    resetRemarksColumnSettings: build.mutation<ResetRemarksColumnSettingsResponse, ResetColumnSettingsRequest>({
      query: ({ projectId }) => ({
        url: `/shortcoming/${projectId}/list/view/reset`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RemarksTableColumns'],
    }),
    // if remarks exist return true, else return false
    getRemarksHavingStatus: build.query<boolean, number>({
      query: (projectId) => ({
        url: `/shortcoming/${projectId}/list/experimental?num=1`,
        method: 'POST',
        body: {
          filter: null,
          sort: null,
        },
      }),
      transformResponse: (response: GetRemarksResponse) => {
        return !!response.data.length
      },
      providesTags: ['Remarks'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetRemarksQuery,
  useGetRemarksByPrevIdQuery,
  useGetRemarkQuery,
  useCreateRemarkSecondaryMutation,
  useCreateRemarkMutation,
  useGetNextRemarkNumberQuery,
  useEditRemarkMutation,
  useEditRemarkCopyMutation,
  useLazyEditRemarkStatusQuery,
  useUploadImagesToRemarkMutation,
  useDeleteImagesFromRemarkMutation,
  useDeleteRemarkMutation,
  useDeleteRemarkCopyMutation,
  useGetRemarkAuditQuery,
  useGetRemarkAuditSecondaryQuery,
  useGetRemarksFilteredListQuery,
  useGetRemarkEliminationQuery,
  useChangeRemarkEliminationMutation,
  useUploadMediaToEliminationMutation,
  useDeleteMediaFromEliminationMutation,
  useChangeRemarkNotificationMutation,
  useGetDropdownCompanySendersQuery,
  useLazyGetDropdownSendersQuery,

  useLazyCheckRemarkForFilterSatisfactionQuery,
  useLazyCheckRemarkForEmailNotificationQuery,
  useLazyCheckRemarkStatusForEmailNotificationQuery,

  useGetRemarksColumnSettingsQuery,
  useSetRemarksColumnSettingsMutation,
  useResetRemarksColumnSettingsMutation,

  useGetResponsibleEliminationQuery,
  useGetDropdownObjectsQuery,
  useGetDropdownActivityTypesQuery,
  useGetDropdownContractorsQuery,
  useLazyGetDescriptionsForRemarkQuery,
  useGetDropdownBasisQuery,
  useLazyGetDropdownResponsibleQuery,
  useGetRemarksHavingStatusQuery,
} = remarksApi
