import { TCallStatus, TCallStatusRu } from 'api/calls/types'
import {
  PRESCRIPTION_COMPLETE_STATUS_COLOR,
  PRESCRIPTION_CREATED_STATUS_COLOR,
  PRESCRIPTION_DISCARDED_STATUS_COLOR,
  PRESCRIPTION_SENT_STATUS_COLOR,
} from 'utils/constants'
import { LegendColors } from './global'

export const callStatuses: TCallStatus[] = ['CREATED', 'SENT', 'CANCELLED', 'COMPLETE']

export const callStatusRuByEn: Record<TCallStatus, TCallStatusRu> = {
  CREATED: 'Создан',
  SENT: 'Направлен',
  CANCELLED: 'Отменен',
  COMPLETE: 'Завершен',
}

export const callStatusColorByEn: Record<TCallStatus, LegendColors> = {
  CREATED: PRESCRIPTION_CREATED_STATUS_COLOR,
  SENT: PRESCRIPTION_SENT_STATUS_COLOR,
  CANCELLED: PRESCRIPTION_DISCARDED_STATUS_COLOR,
  COMPLETE: PRESCRIPTION_COMPLETE_STATUS_COLOR,
}
