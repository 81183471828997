import { FC } from 'react'
import { AuditCallItemProps } from './AudtiCallItem.types'
import { CallAuditAction } from 'api/calls/calls.types'
import { AuditInnerInfo } from '../NewAuditItem/components/AuditInfoItem/AuditInfoItem.types'
import { Person as PersonIcon, Info as InfoIcon } from '@mui/icons-material'
import { connectNames } from 'utils/connectNames'
import { userRolesEnToRu } from 'core/types/user'
import { AuditStatusGraduation } from '../NewAuditItem/components/AuditStatusGraduation'
import { callStatusRuByEn } from 'core/types/call'
import { NewAuditItem } from '../NewAuditItem'

export const AuditCallItem: FC<AuditCallItemProps> = ({ callAudit }) => {
  const { action, timestamp, user: userData, value: currentStatus, previous: previousStatus } = callAudit || {}
  const { role, user: user } = userData

  const callAuditTitleByAction: Record<CallAuditAction, string> = {
    CREATE: 'Создан вызов',
    STATUS: 'Изменен статус',
  }

  const userText = `${connectNames(user, true)}, ${userRolesEnToRu[role]}`

  const callInfoData: AuditInnerInfo[] = [
    { icon: <PersonIcon fontSize={'medium'} color={'secondary'} />, text: 'Автор', value: userText },
    {
      icon: <InfoIcon fontSize={'medium'} color={'secondary'} />,
      text: 'Статус',
      value: (
        <AuditStatusGraduation previous={callStatusRuByEn[previousStatus]} current={callStatusRuByEn[currentStatus]} />
      ),
    },
  ]

  return (
    <NewAuditItem
      auditData={{
        title: callAuditTitleByAction[action],
        timestamp,
        info: callInfoData,
      }}
    />
  )
}
