import { FC, useState } from 'react'
import { IAuditData, ICallAuditCellProps } from './CallAuditCell.types'
import { History as HistoryIcon } from '@mui/icons-material'
import { CallAuditCellWrapper } from './CallAuditCell.styles'
import { AuditDrawer } from 'pages/Remarks/components/AuditDrawer'

export const CallAuditCell: FC<ICallAuditCellProps> = (props) => {
  const { data } = props
  const [auditData, setAuditData] = useState<IAuditData | null>(null)

  const onAuditOpen = () => {
    setAuditData({ openedAuditId: data!.id, openedAuditCallNumber: data!.number })
  }

  const onAuditClose = () => {
    setAuditData(null)
  }

  return (
    <>
      <CallAuditCellWrapper onClick={onAuditOpen}>
        <HistoryIcon fontSize='medium' color='disabled' />
      </CallAuditCellWrapper>

      <AuditDrawer
        variant='call'
        openedAuditId={auditData?.openedAuditId || null}
        openedAuditCallNumber={auditData?.openedAuditCallNumber || null}
        onClose={onAuditClose}
      />
    </>
  )
}
