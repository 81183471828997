import { Stack } from '@mui/material'
import { Tooltip } from 'components/Tooltip'
import styled from 'styled-components'

export const StyledTooltip = styled(Tooltip)``

export const AuditDrawerWrapper = styled(Stack)`
  width: 480px;
  height: 100%;
`

export const AuditDrawerContentWrapper = styled(Stack)`
  overflow: auto;

  & .MuiDivider-root {
    height: 1px;
  }
`

interface IconWrapperProps {
  imgColor: string
}

export const IconWrapper = styled(Stack)<IconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 100%;
  width: 14px;
  height: 14px;

  & svg {
    fill: ${(props) => props.imgColor};
    width: 12px;
  }
`
