import { FC } from 'react'
import { IDialogProps } from './Dialog.types'
import { Dialog as MUIDialog } from '@mui/material'
import { StyledIconButton } from './Dialog.styles'
import { Close as CloseIcon } from '@mui/icons-material'

export const Dialog: FC<IDialogProps> = ({ open, onClose, children }) => {
  return (
    <MUIDialog open={open} onClose={onClose}>
      <>
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
        {children}
      </>
    </MUIDialog>
  )
}
