import { ShortUserInfoWithPosition } from 'api/prescriptions/types'
import { UserProfile } from 'api/profile/types'
import { ProjectMember } from 'api/references/projectMembers/types'
import { Representative } from 'api/references/representatives/types'
import { Media } from 'core/types/global'

export interface ICallShort {
  status: TCallStatus
  assignmentType: ICallAssignmentType
  description: string
  period: ICallPeriod
  phone: string
  responsibleCompany: ICallResponsibleCompany
  responsibleUser: ICallResponsibleUser
  type: TCallType
}

export interface ICallFull extends Omit<ICallShort, 'responsibleCompany' | 'responsibleUser' | 'assignmentType'> {
  id: number
  number: string
  responsibleCompany: ICallFullResponsibleCompany
  responsibleUser: ICallFullResponsibleUser
  assignmentType: ICallFullAssignmentType
  attachments: Media[]
  createdDate: string
  author: UserProfile
  period: ICallFullPeriod
}

export type TCallStatus = 'CREATED' | 'SENT' | 'CANCELLED' | 'COMPLETE'
export type TCallStatusRu = 'Создан' | 'Направлен' | 'Отменен' | 'Завершен'

export interface ICallAssignmentType {
  assignmentTypeId: number | null
  custom: string | null
}

export interface ICallPeriod {
  expectedDate: string
}

export interface ICallFullPeriod extends ICallPeriod {
  deviation: number | null
  isExpired: boolean
}

export interface ICallResponsibleCompany {
  company: string | null
  projectMember: number | null
}

export interface ICallResponsibleUser {
  engineerQC: number | null
  representative: number | null
}

export type TCallType = 'OSR' | 'OVR' | 'OOK'

export interface ICallFullResponsibleCompany {
  company: string | null
  projectMember: ProjectMember | null
}

export interface ICallFullResponsibleUser {
  representative: Representative | null
  user: ShortUserInfoWithPosition | null
}

export interface ICallFullAssignmentType {
  id: number | null
  description: string | null
  note: string | null
  title: string
}

export const callTypeRuByEn: Record<TCallType, string> = {
  OSR: 'Освидетельствование скрытых работ',
  OVR: 'Освидетельствование выполненных работ',
  OOK: 'Освидетельствование ответственных конструкций',
}
