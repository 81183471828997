import { Stack, Typography } from '@mui/material'
import { SimpleHeader } from 'components/SimpleHeader'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { theme } from 'styles/theme'
import { FormItemsWrapper, FormWrapper, FullWrapper, Wrapper } from './CallForm.styles'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { GeneralInfo } from './components/GeneralInfo'
import { Button } from 'components/Button'
import { Documents } from './components/Documents'
import { ICallFormData, TCallFormDialogTrigger } from './CallForm.types'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { useNavigate, useParams } from 'react-router-dom'
import { callValidationSchema } from './CallForm.validation'
import {
  useCreateCallMutation,
  useDeleteCallMutation,
  useDeleteFilesFromCallMutation,
  useEditCallMutation,
  useGetCallByIdQuery,
  useGetNextCallNumberQuery,
  useImportFilesToCallMutation,
} from 'api/calls'
import { useTypedSelector } from 'store/store'
import { profileSelector } from 'store/slices/profile'
import { connectNames } from 'utils/connectNames'
import { Details } from './components/Details'
import { Inspection } from './components/Inspection'
import { addDays } from 'date-fns'
import { callTypeRuByEn, ICallShort, TCallType } from 'api/calls/types'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { useSnackbar } from 'notistack'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Progress } from 'components/Progress'

export const CallForm: FC = () => {
  const navigate = useNavigate()
  const profile = useTypedSelector(profileSelector)
  const { role } = profile || {}
  const isContractor: boolean = role === 'contractor'
  const { projectId: projectIdString, callId: callIdString } = useParams()
  const projectId = Number(projectIdString)
  const callId = Number(callIdString) || undefined
  const { enqueueSnackbar } = useSnackbar()

  const { data: nextNumber, isFetching: isNextNumberFetching } = useGetNextCallNumberQuery(projectId, {
    skip: !!callId,
  })

  const { data: callData, isFetching: isCallDataFetching } = useGetCallByIdQuery(
    {
      projectId,
      callId: callId!,
    },
    {
      skip: !callId,
    },
  )

  const {
    status,
    number,
    createdDate,
    responsibleCompany,
    responsibleUser,
    phone,
    attachments,
    type,
    assignmentType,
    description,
    period,
    author,
  } = callData?.data || {}

  const parsedCreatedDate = parseResponseDate(createdDate).date
  const parsedExpectedDate = parseResponseDate(period?.expectedDate).date

  const [createCall, { isLoading: isCreating, ...createCallResponse }] = useCreateCallMutation()
  const [editCall, { isLoading: isEditing, ...editCallResponse }] = useEditCallMutation()
  const [deleteCall, { isLoading: isDeleting, ...deleteCallResponse }] = useDeleteCallMutation()

  const [importFilesToCall, { isLoading: isFilesUploading, ...importFilesToCallResponse }] =
    useImportFilesToCallMutation()
  const [deleteFilesFromCall, { isLoading: isFilesDeleting, ...deleteFilesFromCallResponse }] =
    useDeleteFilesFromCallMutation()
  const isQuerying = isCreating || isFilesUploading || isEditing || isFilesDeleting

  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const initialValues = useMemo(() => {
    const data: ICallFormData = {
      status: status || 'CREATED',
      number: number || nextNumber || '',
      createdDate: parsedCreatedDate || new Date(),
      responsibleCompany:
        responsibleCompany?.company || responsibleCompany?.projectMember || profile.company.userCompanyName // если я подрядчик, то редактировать нельзя и запрос на список не нужен
          ? {
              id:
                responsibleCompany?.company || responsibleCompany?.projectMember?.id || profile.company.userCompanyName,
              value:
                responsibleCompany?.company ||
                responsibleCompany?.projectMember?.shortName ||
                profile.company.userCompanyName,
              type: responsibleCompany?.projectMember ? 'projectMember' : 'company',
            }
          : null,
      responsibleUser:
        responsibleUser?.representative || responsibleUser?.user || profile // если подрядчик, то список только по компании
          ? {
              id: responsibleUser?.representative?.id || responsibleUser?.user?.id || profile?.id || 0,
              value: responsibleUser?.representative?.fullName || connectNames(responsibleUser?.user || profile),
              subtext:
                responsibleUser?.representative || responsibleUser?.user
                  ? responsibleUser?.representative?.position || responsibleUser?.user?.position
                  : profile.company.userPosition || '',
              type: responsibleUser?.representative ? 'representative' : 'engineerQC',
            }
          : null,
      phone: phone || profile.phone || '',

      type: type ? { id: type, value: callTypeRuByEn[type] } : null,
      assignmentType: assignmentType
        ? {
            id: assignmentType?.id || 0,
            value: assignmentType?.title || '',
            type: assignmentType?.id ? '' : 'custom',
          }
        : null,
      description: description || '',
      expectedDate: parsedExpectedDate || addDays(new Date(), 3),

      files: attachments || [],
      filesForCreate: [],
      filesIdsToDelete: [],
    }

    return data
  }, [nextNumber, profile, callData])

  const onSubmit = useCallback((values: ICallFormData) => {
    const { status, responsibleCompany, responsibleUser, assignmentType, description, expectedDate, phone, type } =
      values

    const dataForRequest: ICallShort = {
      status,
      responsibleCompany: {
        company: responsibleCompany?.type === 'company' ? responsibleCompany.value : null,
        projectMember: responsibleCompany?.type === 'projectMember' ? Number(responsibleCompany.id) : null,
      },
      responsibleUser: {
        engineerQC: responsibleUser?.type === 'engineerQC' ? Number(responsibleUser?.id) : null,
        representative: responsibleUser?.type === 'representative' ? Number(responsibleUser?.id) : null,
      },
      assignmentType: {
        assignmentTypeId: assignmentType?.type !== 'custom' ? Number(assignmentType?.id) : null,
        custom: assignmentType?.type === 'custom' ? assignmentType?.value : null,
      },
      description,
      period: {
        expectedDate: formatDateForServer(expectedDate),
      },
      phone,
      type: type?.id as TCallType,
    }

    if (callId) editCall({ projectId, callId, body: dataForRequest })
    else createCall({ projectId, body: dataForRequest })
  }, [])

  const readOnly: boolean =
    !!callId &&
    !(
      role === 'admin' ||
      (isContractor && initialValues.status !== 'COMPLETE') ||
      (role === 'engineer_qc' && profile.company.userCompanyName === author?.company.userCompanyName)
    )

  const deleteButtonShown = callId && (isContractor ? initialValues.status === 'CREATED' : !readOnly)

  const { formik } = useForm({
    validationSchema: callValidationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit,
  })

  const { values, setFieldValue, dirty, isValid, setValues, setTouched } = formik

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) onReturnClick(true)
  }, [])

  const handleDeleteConfirm = useCallback((confirm: boolean) => {
    if (confirm) deleteCall({ projectId, callId: callId! })
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<TCallFormDialogTrigger>('exit')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    exit: { handleConfirm: handleExitConfirm },
    delete: {
      handleConfirm: handleDeleteConfirm,
      title: 'Удалить вызов?',
      body: 'Вызов будет удален.',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onDeleteClick = () => {
    setConfirmDialogTrigger('delete')
    openConfirm()
  }

  const onReturnClick = (immediately?: boolean) => {
    const id = callId ? `?id=${callId}` : ''

    if (immediately || !dirty) navigate(`/project/${projectId}/calls` + id)
    else openConfirm()
  }

  useMutationHandlers(createCallResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    if (values.filesForCreate.length) {
      importFilesToCall({ projectId, callId: data.data.id, files: [...values.filesForCreate].reverse() })
      return
    }

    onReturnClick(true)
    enqueueSnackbar('Вызов успешно добавлен.', { variant: 'success' })
  })

  useMutationHandlers(importFilesToCallResponse, (data) => {
    // if (!data.success) {
    //   enqueueSnackbar(data.description, { variant: 'error' })
    //   return
    // }

    onReturnClick(true)
    enqueueSnackbar(callId ? 'Вызов успешно изменён.' : 'Вызов успешно добавлен.', { variant: 'success' })
  })

  useMutationHandlers(editCallResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    if (values.filesForCreate.length)
      importFilesToCall({ projectId, callId: callId!, files: [...values.filesForCreate].reverse() })

    if (values.filesIdsToDelete.length)
      deleteFilesFromCall({ projectId, callId: callId!, filesIds: values.filesIdsToDelete })

    if (values.filesForCreate.length || values.filesIdsToDelete.length) return

    onReturnClick(true)
    enqueueSnackbar('Вызов успешно изменён.', { variant: 'success' })
  })

  useMutationHandlers(deleteFilesFromCallResponse, (data) => {
    // if (!data.success) {
    //   enqueueSnackbar(data.description, { variant: 'error' })
    //   return
    // }

    onReturnClick(true)
    enqueueSnackbar('Вызов успешно изменён.', { variant: 'success' })
  })

  useMutationHandlers(deleteCallResponse, (data) => {
    // if (!data.success) {
    //   enqueueSnackbar(data.description, { variant: 'error' })
    //   return
    // }

    onReturnClick(true)
    enqueueSnackbar('Вызов успешно удалён.', { variant: 'success' })
  })

  if (isNextNumberFetching || isCallDataFetching) return <Progress />

  return (
    <Stack width={'100%'} bgcolor={theme.palette.bg.white}>
      <SimpleHeader
        title={readOnly ? 'Просмотр вызова' : callId ? 'Редактирование вызова' : 'Создание вызова'}
        onClose={() => onReturnClick()}
      />

      <FormWrapper>
        <FormikProvider value={formik}>
          <Stack component={Form}>
            <FullWrapper>
              <FormItemsWrapper>
                <Wrapper spacing={2.5}>
                  <GeneralInfo readOnly={readOnly} />
                  <Documents readOnly={readOnly} />
                </Wrapper>

                <Details readOnly={readOnly} />

                <Inspection />
              </FormItemsWrapper>

              <Stack direction='row' spacing={2} justifyContent={'flex-end'} paddingRight={1.5}>
                {deleteButtonShown && (
                  <Button
                    onClick={onDeleteClick}
                    icon={true}
                    size='medium'
                    color='error'
                    variant='outlined'
                    style={{ maxWidth: '100%' }}
                  >
                    <DeleteIcon style={{ fill: theme.palette.error.main }} />
                    <Typography color={theme.palette.error.main} marginLeft={1}>
                      Удалить вызов
                    </Typography>
                  </Button>
                )}
                {!readOnly && (
                  <Button
                    type='submit'
                    disabled={!dirty || !isValid}
                    loading={isFormLoading}
                    color='success'
                    size='medium'
                  >
                    Сохранить
                  </Button>
                )}
                <Button size='medium' onClick={() => onReturnClick()}>
                  Закрыть
                </Button>
              </Stack>
            </FullWrapper>
          </Stack>
        </FormikProvider>
      </FormWrapper>

      <ConfirmDialog />
    </Stack>
  )
}
