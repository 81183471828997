import { Stack, Typography } from '@mui/material'
import { useGetCallsQuery } from 'api/calls'
import { FC, useCallback, useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  CellClickedEvent,
  ClientSideRowModelModule,
  ColDef,
  GetRowIdParams,
  GridOptions,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community'
import { callTypeRuByEn, ICallFull, TCallType } from 'api/calls/types'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { connectNames } from 'utils/connectNames'
import { TABLE_CELL_HEIGHT } from 'pages/Remarks/components/RemarksTable'
import { StyledCallTable } from './CallTable.styles'
import { Progress } from 'components/Progress'
import { EmptyPage } from 'components/EmptyPage'
import { CallStatusCell } from './cells/CallStatusCell'
import { CallAuditCell } from './cells/CallAuditCell'
import { ModuleRegistry } from 'ag-grid-community'
import { ClientSideRowModelApiModule } from 'ag-grid-community'
import { CallPhoneCell } from './cells/CallPhoneCell'
import { CallExpectedDateCell } from './cells/CallExpectedDateCell'
import { AgGridReact } from 'ag-grid-react'
import { ScrollApiModule } from 'ag-grid-community'
import useSearchParams from 'hooks/useSearchParams'
import { isNumber } from 'lodash'

ModuleRegistry.registerModules([ClientSideRowModelApiModule, ScrollApiModule])

const columnDefs: ColDef[] = [
  {
    field: 'number',
    headerName: '№',
    width: 64,
    resizable: false,
  },
  {
    field: 'status',
    headerName: 'Статус вызова',
    cellRenderer: CallStatusCell,
    initialWidth: 172,
    minWidth: 172,
    flex: 1,
    editable: true,
  },
  {
    field: 'type',
    headerName: 'Тип вызова',
    valueFormatter: ({ value }: ValueFormatterParams) => callTypeRuByEn[value as TCallType],
    initialWidth: 212,
    minWidth: 212,
    flex: 1,
  },
  {
    field: 'assignmentType.title',
    headerName: 'Вид работ',
    initialWidth: 224,
    minWidth: 224,
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Описание',
    initialWidth: 300,
    minWidth: 300,
    flex: 1,
  },
  {
    field: 'createdDate',
    headerName: 'Дата вызова',
    valueFormatter: ({ value }: ValueFormatterParams) => parseResponseDate(value || '').fullDate,
    initialWidth: 200,
    minWidth: 155,
    flex: 1,
  },
  {
    field: 'period',
    headerName: 'Предполагаемая дата осмотра',
    cellRenderer: CallExpectedDateCell,
    initialWidth: 200,
    minWidth: 155,
    flex: 1,
  },
  {
    field: 'author',
    headerName: 'Автор вызова',
    initialWidth: 300,
    minWidth: 150,
    cellRenderer: ({ value }: ICellRendererParams) => (
      <Stack>
        <span>{connectNames(value)}</span>
        <Typography variant='body2'>{value.company.userPosition}</Typography>
      </Stack>
    ),
    flex: 1,
  },
  {
    field: 'responsibleCompany',
    headerName: 'Организация — инициатор вызова',
    valueFormatter: ({ value }: ValueFormatterParams) => value.company || value.projectMember?.shortName,
    initialWidth: 300,
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'responsibleUser',
    headerName: 'Представитель инициатора вызова',
    valueFormatter: ({ value }: ValueFormatterParams) =>
      value.user ? connectNames(value.user) : value.representative?.fullName,
    initialWidth: 300,
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'phone',
    headerName: 'Телефон представителя',
    cellRenderer: CallPhoneCell,
    initialWidth: 193,
    minWidth: 193,
    flex: 1,
  },
  {
    field: 'audit',
    headerName: '',
    width: 64,
    resizable: false,
    cellRenderer: CallAuditCell,
  },
]

export const CallTable: FC = () => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const navigate = useNavigate()
  const tableRef = useRef<AgGridReact<ICallFull> | null>(null)
  const searchParams = useSearchParams()

  const { data, isLoading, isFetching } = useGetCallsQuery({ projectId })

  // const rowData: ICallFull[] = data?.data || []
  const rowData: ICallFull[] = useMemo(() => {
    return data?.data || []
  }, [isFetching, projectId])

  const getRowId = useCallback(({ data }: GetRowIdParams<ICallFull>) => {
    return String(data.id)
  }, [])

  const gridOptions: GridOptions = {
    headerHeight: 64,
    rowHeight: TABLE_CELL_HEIGHT,
    enableCellTextSelection: true,
    columnHoverHighlight: true,
  }

  const onRowClick = (e: CellClickedEvent<ICallFull>) => {
    const { data, column } = e
    const customClickCells = ['status', 'phone', 'audit']

    if (!customClickCells.includes(column.getColId())) navigate(`edit/${data?.id}`)
  }

  const scrollToRow = () => {
    const id = searchParams.get('id')
    const { rowIndex } = tableRef.current?.api.getRowNode(String(id)) || {}
    const node = tableRef.current?.api.getRowNode(String(id))

    if (isNumber(rowIndex)) {
      tableRef.current?.api.ensureIndexVisible(rowIndex!, 'middle')
      node?.setSelected(true)

      setTimeout(() => {
        const rowElement = document.querySelector(`[row-index="${rowIndex}"]`)
        rowElement?.classList.add('ag-row-hover')
      }, 100)
    }

    searchParams.del('id')
  }

  if (isFetching) return <Progress />

  if (!isFetching && !rowData.length) return <EmptyPage data={{ text: 'Вызовы отсутствуют.', buttons: [] }} fullPage />

  return (
    <Stack width={'100%'} pr={2.5} height={'100%'}>
      {/* @ts-ignore */}
      <StyledCallTable
        ref={tableRef}
        columnDefs={columnDefs}
        rowData={rowData}
        modules={[ClientSideRowModelModule, ClientSideRowModelApiModule]}
        onCellClicked={onRowClick}
        suppressCellFocus={true}
        gridOptions={gridOptions}
        getRowId={getRowId}
        onGridReady={scrollToRow}
      />
    </Stack>
  )
}
