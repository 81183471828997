import { TABLE_CELL_HEIGHT } from '../../RemarksTable.styles'
import { RemarksStatusCell, StatusCellContent } from './StatusCell.styles'
import { isRegulationsStatusCell, isWorksStatusCell, StatusCellProps } from './StatusCell.types'
import { KeyboardArrowDown as ArrowIcon } from '@mui/icons-material'
import { IconButton, Menu, Stack } from '@mui/material'
import { CreateWork } from 'api/ksg/api.types'
import { RegulationShort } from 'api/regulations/types'
import { RemarkStatuses } from 'api/remarks/types'
import { ColoredTitle } from 'components/ColoredTitle'
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection'
import { WorkStatuses } from 'core/types/ksg'
import { PrescriptionStatus } from 'core/types/prescription'
import { RegulationStatus } from 'core/types/regulation'
import { FC, MouseEvent, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isUserHighAccessSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export const StatusCell: FC<StatusCellProps> = ({
  variant,
  info,
  itemsData,
  statusData,
  onSelect,
  editingAvailable = true,
}) => {
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)

  const { row, column } = info
  const { minSize, size, maxSize } = column.columnDef
  const item = row.original
  const { id } = item

  const { projectId: projectIdStr } = useParams<{ projectId: string }>()
  const projectId = parseInt(projectIdStr!)

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)

  const onStatusCellClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setMenuAnchor(e.currentTarget)
  }, [])

  const onMenuClose = useCallback(() => {
    setMenuAnchor(null)
  }, [])

  const onMenuClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  const ColoredStatus = <ColoredTitle body={statusData.body} color={statusData.color} />

  const onMenuItemClick = useCallback(
    (status: RemarkStatuses | PrescriptionStatus) => {
      // @ts-ignore
      onSelect(projectId, id, status)
    },
    [projectId, id, onSelect],
  )

  const onRegulationsMenuItemClick = useCallback(
    (status: RegulationStatus) => {
      if (!isRegulationsStatusCell(item)) {
        return
      }

      const sectionId = item.section?.id

      const body: RegulationShort = {
        accepted: item?.accepted,
        body: item?.body,
        comment: item?.comment,
        identifier: item?.identifier,
        link: item?.link,
        name: item?.name,
        section: sectionId,
        status: status,
        type: item?.type,
      }
      // @ts-ignore
      onSelect(sectionId, item.id, body)
    },
    [item, onSelect],
  )

  const onWorksMenuItemClick = useCallback(
    (status: WorkStatuses) => {
      if (!isWorksStatusCell(item)) {
        return
      }

      const body: CreateWork = {
        // @ts-ignore
        assignmentType: item.assignmentType,
        capacity: {
          total: item.capacity.total || null,
          unit: item.capacity.unit,
          confirmed: item.capacity.confirmed || null,
        },
        // @ts-ignore
        contractor: item.contractor,
        documentCypher: item.documentCypher,
        end: item.period?.end,
        identifier: item.identifier,
        name: item.name,
        // @ts-ignore
        object: item.object,
        qcInfo: {
          comment: item.qcInfo?.comment || null,
          date: status === 'ACCEPT' || status === 'PARTIAL' ? item.qcInfo?.date : null,
          status: status === 'EMPTY' ? null : status,
        },
        // @ts-ignore
        rd: item.rd,
        start: item.period?.start,
      }

      // @ts-ignore
      onSelect(projectId, item.id, body)
    },
    [item, onSelect],
  )

  const menuItemClickHandler =
    (status: RemarkStatuses | PrescriptionStatus | RegulationStatus | WorkStatuses) => () => {
      setMenuAnchor(null)

      if (variant === 'regulations') {
        onRegulationsMenuItemClick(status as RegulationStatus)
        return
      }

      if (variant === 'works') {
        onWorksMenuItemClick(status as WorkStatuses)
        return
      }

      onMenuItemClick(status as RemarkStatuses | PrescriptionStatus)
    }

  return (
    <RemarksStatusCell
      key={id}
      style={{
        minWidth: minSize,
        width: size,
        maxWidth: maxSize,
      }}
      onClick={isUserHighAccess && editingAvailable ? onStatusCellClick : undefined}
      hoverable={isUserHighAccess && editingAvailable}
    >
      <Stack height={TABLE_CELL_HEIGHT} justifyContent='center'>
        {isUserHighAccess ? (
          <>
            <StatusCellContent pl={'7px'}>
              {ColoredStatus}
              <IconButton style={{ opacity: editingAvailable ? 1 : 0 }}>
                <ArrowIcon fontSize='small' />
              </IconButton>
            </StatusCellContent>

            <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClick={onMenuClick} onClose={onMenuClose}>
              {(itemsData || []).map(({ value, valueForOnClick, children, checked, key }) => {
                if (value) {
                  return (
                    <StyledSelectMenuItem
                      onClick={menuItemClickHandler(valueForOnClick)}
                      style={{ width: size, maxWidth: '100%' }}
                      value={value}
                      checked={checked}
                      key={key}
                    >
                      {children}
                    </StyledSelectMenuItem>
                  )
                }
              })}
            </Menu>
          </>
        ) : (
          <StatusCellContent>{ColoredStatus}</StatusCellContent>
        )}
      </Stack>
    </RemarksStatusCell>
  )
}
