import { TCallType } from 'api/calls/types'

export interface IDetailsProps {
  readOnly: boolean
}

export const callTypeDropdown: { id: TCallType; text: string }[] = [
  { id: 'OSR', text: 'Освидетельствование скрытых работ' },
  { id: 'OVR', text: 'Освидетельствование выполненных работ' },
  { id: 'OOK', text: 'Освидетельствование ответственных конструкций' },
]
