import { Wrapper as MediaWrapper } from 'pages/Remarks/components/RemarkForm/Media/Media.styles'
import styled from 'styled-components'

export const Wrapper = styled(MediaWrapper)`
  width: 34.6%;

  @media (max-width: 1555px) {
    width: 99.5%;
    margin-top: 20px;
  }
`
