import { FC } from 'react'
import { Wrapper } from './Details.styles'
import { CustomSelect } from 'components/CustomSelect'
import { theme } from 'styles/theme'
import { Stack, Typography } from '@mui/material'
import { FieldForm } from 'components/FieldForm'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { EventAvailable as CalendarIcon } from '@mui/icons-material'
import { useFormikContext } from 'formik'
import { ICallFormData } from '../../CallForm.types'
import { callTypeDropdown, IDetailsProps } from './Details.types'
import { useParams } from 'react-router-dom'
import { useGetDropdownAssignmentTypeQuery } from 'api/calls'

export const Details: FC<IDetailsProps> = ({ readOnly }) => {
  const { values, setFieldValue } = useFormikContext<ICallFormData>()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { data: assignmentTypesDropdown } = useGetDropdownAssignmentTypeQuery({ projectId })

  return (
    <Wrapper spacing={2.5}>
      <CustomSelect
        name='type'
        label='Тип вызова'
        labelProps={{
          style: { paddingLeft: 0, color: theme.palette.text.dark, fontSize: '16px' },
          variant: 'subtitle1',
        }}
        variant='textarea'
        list={callTypeDropdown}
        placeholder={'Выберите из списка'}
        readOnly={readOnly}
        style={{
          textAlign: 'start',
        }}
        isRequired
      />

      <CustomSelect
        name='assignmentType'
        label='Вид работ'
        labelProps={{
          style: { paddingLeft: 0, color: theme.palette.text.dark, fontSize: '16px' },
          variant: 'subtitle1',
        }}
        variant='textarea'
        list={assignmentTypesDropdown?.data || []}
        placeholder={'Выберите из списка'}
        readOnly={readOnly}
        style={{
          textAlign: 'start',
        }}
        isRequired
        free
      />

      <Stack>
        <Stack direction={'row'}>
          <Typography variant='subtitle1' textAlign={'start'} marginBottom={'6px'} color={theme.palette.text.dark}>
            Описание
          </Typography>
          <Typography fontSize={14} color={theme.palette.error.main}>
            &nbsp;*
          </Typography>
        </Stack>
        <FieldForm
          version='project'
          name='description'
          placeholder={'Укажите описание'}
          multiline
          helperText=''
          inputProps={{
            readOnly,
            style: {
              minHeight: '140px',
            },
          }}
        />
      </Stack>

      <Stack>
        <Typography variant='subtitle1' textAlign={'start'} marginBottom={'6px'} color={theme.palette.text.dark}>
          Сроки
        </Typography>

        <FieldItem
          title='Предполагаемая дата осмотра'
          icon={<CalendarIcon fontSize={'medium'} color={'secondary'} />}
          isRequired={true}
        >
          <FieldForm
            version='date'
            name='expectedDate'
            placeholder={'дд.мм.гггг'}
            helperText=''
            dataValue={values.expectedDate}
            onDataChange={(value: Date | null) => setFieldValue('expectedDate', value)}
            style={{ maxWidth: '144px', width: '100%' }}
            dateFieldProps={{
              minDate: values.createdDate || undefined,
              readOnly,
            }}
          />
        </FieldItem>
      </Stack>
    </Wrapper>
  )
}
