import { FC } from 'react'
import { ICallExpectedDateCellProps } from './CallExpectedDateCell.types'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { DeviationElement, DeviationWrapper } from './CallExpectedDateCell.styles'

export const CallExpectedDateCell: FC<ICallExpectedDateCellProps> = (props) => {
  const { data } = props
  const { deviation } = data?.period || {}

  return (
    <DeviationWrapper
      direction={'row'}
      alignItems={'center'}
      spacing={1}
      justifyContent={'center'}
      margin={'0 auto'}
      position={'relative'}
    >
      <span>{parseResponseDate(data?.period.expectedDate || '').fullDate}</span>
      {!!deviation && <DeviationElement>+{deviation}</DeviationElement>}
    </DeviationWrapper>
  )
}
