export interface AuditDrawerProps {
  variant: AuditDrawerVariant
  openedAuditId: number | null
  openedAuditRemarkNumber?: string | null
  openedAuditPrimaryId?: number | null
  openedAuditCallNumber?: string | null
  openedAuditWorkNumber?: number | null
  openedAuditDocNumber?: number | null
  onClose: () => void
}

export type AuditDrawerVariant = 'remarks' | 'prescriptions' | 'ksg' | 'document' | 'call'

export type AuditDrawerRemarkType = 'statusChanges' | 'remarksSecondary'

export const remarkSecondaryStatusChangesNotifyInfo = 'В логе данные об изменении статуса первичного замечания.'

export const initialAuditDrawerRemarkType: AuditDrawerRemarkType = 'statusChanges'
export const initialAuditDrawerRemarkTypeIsSecondary: AuditDrawerRemarkType = 'remarksSecondary'
