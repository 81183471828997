import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

export const callValidationSchema = yup.object({
  number: yup.string().trim(),
  createdDate: yup.date().transform(formatDateInInput).nullable(),
  responsibleCompany: yup.object().required(),
  responsibleUser: yup.object().required(),
  phone: yup.string().required('common:errors.required'),

  type: yup.object().required(),
  assignmentType: yup.object().required(),
  description: yup.string().trim().required('common:errors.required'),
  expectedDate: yup.date().transform(formatDateInInput).min(yup.ref('createdDate')).required('common:errors.required'),

  files: yup.array(yup.mixed()),
  filesForCreate: yup.array(yup.mixed()),
  filesIdsToDelete: yup.array(yup.number()),
})
