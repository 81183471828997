import { Stack } from '@mui/material'
import { formatMaskPhone } from 'components/PhoneFieldForm/PhoneFieldForm.utils'
import { FC } from 'react'
import { ContentCopy as CopyIcon } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { ICallPhoneCellProps } from './CallPhoneCell.types'

export const CallPhoneCell: FC<ICallPhoneCellProps> = (props) => {
  const { value } = props
  const { enqueueSnackbar } = useSnackbar()

  const onPhoneClick = () => {
    navigator.clipboard.writeText(value)
    enqueueSnackbar('Номер скопирован.', { variant: 'success' })
  }

  return (
    <Stack onClick={onPhoneClick} direction='row' spacing={1} alignItems='center' justifyContent='center'>
      <span>{formatMaskPhone(value)}</span>
      <CopyIcon fontSize='small' color='secondary' />
    </Stack>
  )
}
