import { Stack } from '@mui/material'
import styled from 'styled-components'

export const CallAuditCellWrapper = styled(Stack)`
  height: 100%;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: rgba(0, 36, 95, 0.1) -1px -1px 0px inset;

    & .MuiSvgIcon-root {
      color: ${(props) => props.theme.palette.primary.light};
    }
  }
`
